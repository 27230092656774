import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { SpeiPayoutForm } from "pages/payout/logic/forms/SpeiPayoutForm";
import { ReactComponent as SpeiIcon } from "utils/img/spei.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class SpeiPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "spei";

  kind: PayoutKind = PayoutKinds.SPEI;

  name: string = "SPEI";

  mobileName: string = this.name;

  icon: ReactElement = (<SpeiIcon />);

  filenameExample = "sample-spei";

  jsonExample = [
    {
      "CLABE": "123456789123456789",
      Сумма: 23.33,
      Фамилия: "LastName",
      "Имя": "FirstName",
      Примечание: "Note 1",
    },
    {
      "CLABE": "111111111111111111",
      Сумма: 50,
      Фамилия: "LastName",
      "Имя": "FirstName",
      Примечание: "Note 2",
    },
    {
      "CLABE": "222222222222222222",
      Сумма: 5050,
      Фамилия: "LastName",
      "Имя": "FirstName",
      Примечание: "Note 3",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.spei_payouts &&
        this.getService(this.pointManager?.service_config?.spei_payouts)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = () => <></>;

  customerAccountValidationSchema = yup.string().required("Введите CLABE");

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <SpeiPayoutForm payoutMethod={payoutMethod} />
  );
}
