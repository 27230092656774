import React, { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { hexToRGB } from "utils/mixColors";
import { Margin, marginProps, marginStyle } from "UI/utility/maring";

type TextInputProps = {
  label?: string;
  placeholder?: string;
  name?: string;
  type?: "text" | "password" | "number";
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  mask?: string;
  onChange?: (val: string) => void;
  onAccept?: (val: string) => void;
  onClick?: () => void;
  onBlur?: (e: unknown) => void;
  onFocus?: (e: unknown) => void;
  iconStart?: React.ReactElement;
  iconEnd?: React.ReactElement;
  error?: boolean | string;
  helperText?: string | boolean | JSX.Element;
  size?: "small" | "default";
  inputProps?: any;
  style?: React.CSSProperties;
  fullwidth?: boolean;
  multiline?: boolean;
  rows?: number;
} & Margin;

export const TextInput = ({
  label,
  placeholder,
  name,
  type,
  value,
  disabled,
  readOnly,
  mask,
  onChange,
  onAccept,
  onClick,
  onBlur,
  onFocus,
  iconStart,
  iconEnd,
  error,
  helperText,
  size,
  inputProps,
  style,
  multiline = false,
  rows,
  ...otherProps
}: TextInputProps) => {
  const { accentColor } = getColors();
  const [focus, setFocus] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!visible && value) {
      setVisible(true);
    }
  }, [visible]);

  return (
    <>
      <StyledTextInputWrapper
        disabled={disabled}
        style={style}
        {...marginProps(otherProps)}
      >
        {iconStart ? (
          <StyledInputIcon isStart size={size}>
            {iconStart}
          </StyledInputIcon>
        ) : null}
        <StyledFieldset
          disabled={disabled}
          iserror={!!error}
          bordercolor={accentColor}
          size={size}
          focus={focus}
          isMultiline={multiline}
        >
          <StyledLabelInput empty={!label} size={size}>
            {label || ""}
          </StyledLabelInput>
          {multiline ? (
            <StyledTextArea
              type={type || "text"}
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e.target.value)}
              iconstart={iconStart}
              iconend={iconEnd}
              disabled={disabled}
              size={size}
              {...inputProps}
              onMouseDown={() => setFocus(true)}
              onBlur={(e) => {
                setFocus(false);
                if (onBlur) onBlur(e);
              }}
              onFocus={(e) => {
                if (onFocus) onFocus(e);
              }}
              bordercolor={accentColor}
              rows={rows || 2}
            />
          ) : (
            <StyledTextInput
              type={type || "text"}
              placeholder={placeholder}
              name={name}
              value={value}
              iconstart={iconStart}
              iconend={iconEnd}
              disabled={disabled}
              size={size}
              style={style}
              {...inputProps}
              readOnly={readOnly}
              onClick={() => onClick && onClick()}
              onBlur={(e) => {
                setFocus(false);
                if (onBlur) onBlur(e);
              }}
              onFocus={(e) => {
                if (onFocus) onFocus(e);
              }}
              onMouseDown={() => setFocus(true)}
              mask={mask}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange && onChange(e.target.value)
              }
              onAccept={(acceptValue: string) =>
                onAccept && onAccept(acceptValue)
              }
              bordercolor={accentColor}
            />
          )}
        </StyledFieldset>
        {iconEnd ? (
          <StyledInputIcon size={size}>{iconEnd}</StyledInputIcon>
        ) : null}

        {helperText ? (
          <StyledHelperText iserror={!!error}>{helperText}</StyledHelperText>
        ) : null}
      </StyledTextInputWrapper>
    </>
  );
};

const StyledTextInputWrapper = styled.div<{ disabled?: boolean } & Margin>`
  ${(props) => [marginStyle(props)].join("")}

  width: 100%;
  display: flex;
  cursor: text;
  flex-direction: column;
  position: relative;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const StyledTextArea = styled.textarea<StyledInputProps>`
  margin: 0;
  resize: none;
  ${(props) => (props.fullwidth ? "width: 100%;" : "")}
  border: none;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
  padding-right: ${(props) => (props.iconend ? "50px" : "20px")};
  padding-left: ${(props) => (props.iconstart ? "45px" : "15px")};
  ${(props) =>
    props.size === "small"
      ? `
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          padding: 14.5px;
          padding-right: ${props.iconend ? "44px" : "15px"};
          padding-left: ${props.iconstart ? "40px" : "10px"};
        `
      : ""};
  padding-top: 6.5px;
  width: 100%;
  color: #19202e;
  box-sizing: border-box;
  background: transparent;

  &:focus {
    outline: none;
  }

  &:placeholder {
    color: #727272;
  }

  legend {
    color: #c8cfdc;
  }
`;
type StyledInputProps = {
  iconstart?: React.ReactElement;
  iconend?: React.ReactElement;
  size?: string;
  fullwidth?: boolean;
};

const StyledFieldset = styled.fieldset<{
  iserror?: boolean;
  bordercolor: string;
  focus?: boolean;
  size?: string;
  isMultiline: boolean;
}>`
  border: none;
  border: 1px solid ${(props) => (props.iserror ? "#D00025" : "#B9BFC8")};
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  height: 63.5px;
  margin-right: 0;
  margin-left: 0;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.iserror ? "#D00025" : hexToRGB(props.bordercolor, 0.4)};

    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }

  ${(props) =>
    props.focus
      ? `
          border: 1px solid
            ${props.iserror ? "#D00025" : props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
  
  ${(props) =>
    props.size === "small"
      ? props.isMultiline
        ? "height: auto"
        : "height: 54px;"
      : props.isMultiline
      ? "height: auto"
      : ""};

  &:disabled {
    opacity: 0.5;
    border: 1px solid #c8cfdc;

    legend {
      color: #c8cfdc;
    }
  }
`;

export const StyledLabelInput = styled.legend<{
  size?: string;
  empty: boolean;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  color: #19202e;
  max-width: calc(133% - 30px);
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  ${(props) =>
    props.empty
      ? `
          padding-left: 0;
          padding-right: 0;
        `
      : ""};
  ${(props) =>
    props.size === "small"
      ? `
          font-size: 14px;
          line-height: 17px;
          height: 17px;
        `
      : ""};
`;

const StyledTextInput = styled(IMaskInput)<StyledInputProps>`
  ${(props) => (props.fullwidth ? "width: 100%;" : "")}
  margin: 0;
  border: none;
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
  padding-right: ${(props) => (props.iconend ? "50px" : "20px")};
  padding-left: ${(props) => (props.iconstart ? "45px" : "15px")};
  ${(props) =>
    props.size === "small"
      ? `
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          padding: 14.5px;
          padding-right: ${props.iconend ? "44px" : "15px"};
          padding-left: ${props.iconstart ? "40px" : "10px"};
        `
      : ""};
  padding-top: 6.5px;
  width: 100%;
  color: #19202e;
  box-sizing: border-box;
  background: transparent;

  &:focus {
    outline: none;
  }

  &:placeholder {
    color: #727272;
  }
`;

const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { iserror: boolean }) =>
    props.iserror ? "#D00025" : "#727272"};
`;

const StyledInputIcon = styled.div<{
  isStart?: boolean;
  size?: string;
}>`
  position: absolute;
  display: flex;

  svg {
    outline: none;
  }

  svg path {
    // fill: #393939 !important;
  }
  top: 15px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.size === "small"
      ? `
          right: ${props.isStart ? `auto` : "15px"};
          left: ${props.isStart ? `15px` : "auto"};
        `
      : `
          right: ${props.isStart ? `auto` : "20px"};
          left: ${props.isStart ? `20px` : "auto"};
        `};
`;
