import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useNumberFormatter } from "hooks/useNumberFormatter";

export const SummaryCard = ({
  sum,
  senderCurrency,
  sumByRate,
  rate,
  receiverCurrency,
  comment,
  commission,
  commissionCurrency,
}: {
  sum: string;
  sumByRate: string;
  senderCurrency: string;
  receiverCurrency: string;
  rate: string;
  commission: string;
  commissionCurrency: string;
  comment: string;
}) => {
  const { t } = useTranslation();
  const { amountFormat } = useNumberFormatter();
  return (
    <StyledWrapper>
      <StyledTitle>{t("Сводная статистика перевода")}</StyledTitle>
      <StyledBody>
        <StyledBlock>
          <StyledLabel>{t("Сумма перевода")}</StyledLabel>
          {amountFormat(sum)} {senderCurrency}
        </StyledBlock>
        <StyledDivider />
        <StyledBlock>
          <StyledLabel>{`${t(
            "Сумма зачисления по курсу"
          )} ${rate}`}</StyledLabel>
          {sumByRate} {receiverCurrency}
        </StyledBlock>
        <StyledDivider />
        <StyledBlock>
          <StyledLabel>{t("Комиссия")}</StyledLabel>
          {commission} {commissionCurrency}
        </StyledBlock>
        <StyledDivider />
        <StyledCommentBlock>
          <StyledLabel>{t("Комментарий")}</StyledLabel>
          {comment}
        </StyledCommentBlock>
      </StyledBody>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ecf0f6;
`;

const StyledTitle = styled.div`
  padding-top: 24px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  &:before {
    position: absolute;
    top: 100%;
    left: 0px;
    content: "";
    width: 100%;
    height: 20px;
    z-index: 0;
    background: radial-gradient(
        circle at left bottom,
        white 0px,
        white 20px,
        transparent 20px,
        transparent 50%,
        transparent 100%
      ),
      radial-gradient(
        circle at right bottom,
        white 0px,
        white 20px,
        transparent 20px,
        transparent 50%,
        transparent 100%
      );
  }
  &:after {
    position: absolute;
    top: calc(100% + 20px);
    left: 0px;
    content: "";
    width: 100%;
    height: 20px;
    z-index: 0;
    background: radial-gradient(
        circle at left top,
        white 0px,
        white 20px,
        transparent 20px,
        transparent 50%,
        transparent 100%
      ),
      radial-gradient(
        circle at right top,
        white 0px,
        white 20px,
        transparent 20px,
        transparent 50%,
        transparent 100%
      );
  }
`;

const StyledBody = styled.div`
  position: relative;
  padding-top: 20px;
  &:before {
    position: absolute;
    top: 20px;
    left: 0px;
    content: "";
    width: calc(100% - 50px);
    z-index: 0;
    height: 1px;
    margin: 0px 25px 0px 20px;
    background: linear-gradient(
        to right,
        transparent 0px,
        transparent 15px,
        rgba(185, 191, 200, 1) 15px,
        rgba(185, 191, 200, 1) 30px
      )
      0% 0% / 30px repeat-x;
    background-repeat: repeat-x;
  }
`;

const StyledLabel = styled.div`
  font-size: 12px;
  padding-bottom: 12px;
  font-weight: normal;
`;

const StyledBlock = styled.div`
  font-size: 18px;
  font-weight: 800;
  padding: 24px;
`;

const StyledCommentBlock = styled.div`
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 500;
  padding: 24px;
  word-wrap: break-word;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #19202e;
  opacity: 0.1;
`;
