import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { EmailPayInput } from "pages/payout/logic/forms/components/inputs/EmailPayInput";
import { ReactComponent as WhiteBitIcon } from "utils/img/whitebit.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class WhiteBitPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "whitebit";

  kind: PayoutKind = PayoutKinds.WHITE_BIT;

  name: string = "WhiteBit";

  mobileName: string = this.name;

  icon: ReactElement = (<WhiteBitIcon />);

  filenameExample = "sample-whitebit";

  jsonExample = [
    {
      Email: "some@example.com",
      Сумма: 23.33,
      Примечание: "note 1",
    },
    {
      Email: "some2@example.com",
      Сумма: 50,
      Примечание: "note 2",
    },
    {
      Email: "some3@example.com",
      Сумма: 5050,
      Примечание: "note 3",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.whitebit_create_code &&
        this.getService(
          this.pointManager?.service_config?.whitebit_create_code
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <EmailPayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .email("Неправильный формат email")
    .required("Введите email");
}
