import React, { useEffect, useRef, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Switch } from "UI/Form/Switch";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { getTimezone } from "utils/getTimezone";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useDateFormatter } from "hooks/useDateFormatter";
import { Calendar } from "./Calendar";
import { CalendarMonth } from "./CalendarMonth";
import { TimeSelector } from "./TimeSelector";

export const RangeSelector = ({
  rangeStart,
  rangeEnd,
  onChange,
  children,
  right,
  minDate,
  withSwitch,
  valueSwitch,
  setSwitch,
}: {
  rangeStart: DateTime | null;
  rangeEnd: DateTime | null;
  minDate: DateTime | null;
  withSwitch?: boolean;
  valueSwitch?: boolean;
  setSwitch?: (value: boolean) => void;
  onChange: (start: DateTime | null, end: DateTime | null) => void;
  children: React.ReactNode;
  right?: boolean;
}): React.ReactElement => {
  const { t } = useTranslation();

  const { accentColor } = getColors();
  const [showPopUp, setPopUp] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const timezone = getTimezone();
  const { dateFormat } = useDateFormatter();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setPopUp(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  const [start, setStart] = useState<DateTime | null>(null);
  const [end, setEnd] = useState<DateTime | null>(null);

  useEffect(() => {
    if (rangeStart && rangeEnd) {
      setStart(rangeStart);
      setEnd(rangeEnd);
    }
  }, [rangeStart, rangeEnd]);

  // Handlers
  const handleToglePopUp = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPopUp(!showPopUp);
  };

  const handleSelectDate = (date: DateTime) => {
    if (!end && start && date > start) {
      const today = DateTime.local().setZone(timezone);
      const endOfDay = date.endOf("day");
      setEnd(
        endOfDay > today
          ? date.set({ hour: today.hour, minute: today.minute })
          : endOfDay
      );
      return;
    }
    setStart(date.startOf("day"));
    setEnd(null);
  };

  const handleSelectTimeStart = (date: DateTime) => {
    setStart(date);
  };

  const handleSelectTimeEnd = (date: DateTime) => {
    const dateNow = DateTime.local().setZone(timezone);
    setEnd(date > dateNow ? dateNow : date);
  };

  const setRangeHandler = (startDate: DateTime, endDate: DateTime) => {
    setStart(startDate);
    setEnd(endDate);
    setPopUp(false);
    onChange(startDate, endDate);
  };

  const doneHandler = () => {
    setPopUp(false);
    onChange(start, end);
  };

  const currentDateStart = DateTime.local()
    .setZone(timezone)
    .minus({ month: 1 });
  const currentDateEnd = DateTime.local().setZone(timezone);
  const initYearStart = currentDateStart.year;
  const initMonthStart = currentDateStart.month;
  const initYearEnd = currentDateEnd.year;
  const initMonthEnd = currentDateEnd.month;

  const [displayedYearStart, setDisplayedYearStart] = useState(initYearStart);
  const [displayedYearEnd, setDisplayedYearEnd] = useState(initYearEnd);
  const [displayedMonthStart, setDisplayedMonthStart] =
    useState<number>(initMonthStart);
  const [displayedMonthEnd, setDisplayedMonthEnd] =
    useState<number>(initMonthEnd);

  const increeseMonthHandler = () => {
    let nextStart = displayedMonthStart + 1;
    let nextEnd = displayedMonthEnd + 1;
    if (displayedMonthStart === 12) {
      setDisplayedYearStart(displayedYearStart + 1);
      nextStart = 1;
    }
    if (displayedMonthEnd === 12) {
      setDisplayedYearEnd(displayedYearEnd + 1);
      nextEnd = 1;
    }
    setDisplayedMonthStart(nextStart);
    setDisplayedMonthEnd(nextEnd);
  };

  const decreeseMontHandler = () => {
    let nextStart = displayedMonthStart - 1;
    let nextEnd = displayedMonthEnd - 1;
    if (displayedMonthStart === 1) {
      setDisplayedYearStart(displayedYearStart - 1);
      nextStart = 12;
    }
    if (displayedMonthEnd === 1) {
      setDisplayedYearEnd(displayedYearEnd - 1);
      nextEnd = 12;
    }
    setDisplayedMonthStart(nextStart);
    setDisplayedMonthEnd(nextEnd);
  };

  const decreeseYearHandler = () => {
    const nextStart = displayedYearStart - 1;
    const nextEnd = displayedYearEnd - 1;
    setDisplayedYearStart(nextStart);
    setDisplayedYearEnd(nextEnd);
  };

  const increeseYearHandler = () => {
    const nextStart = displayedYearStart + 1;
    const nextEnd = displayedYearEnd + 1;
    setDisplayedYearStart(nextStart);
    setDisplayedYearEnd(nextEnd);
  };

  const handleChangeSwitch = (val: boolean) => {
    if (setSwitch) setSwitch(val);
    setDisplayedYearStart(initYearStart);
    setDisplayedYearEnd(initYearEnd);
    setDisplayedMonthStart(initMonthStart);
    setDisplayedMonthEnd(initMonthEnd);
  };

  const handleSelectMonth = (date: DateTime) => {
    if (
      start &&
      end &&
      start.month === end.month &&
      start.year === end.year &&
      date > start
    ) {
      const today = DateTime.local().setZone(timezone);
      const endOfDay = date.endOf("day").endOf("month");
      setEnd(
        endOfDay > today
          ? date.set({ hour: today.hour, minute: today.minute })
          : endOfDay
      );
      return;
    }
    setStart(date.startOf("month"));
    setEnd(date.endOf("month"));
  };

  return (
    <StyledRangeSelectorWrapper>
      {showPopUp && (
        <StyledRangeSelectorPopUp right={!!right} ref={ref}>
          <StyledMainWrapper>
            <StyledAside>
              {withSwitch && valueSwitch ? (
                <>
                  <Typography style={{ color: "#838383" }}>
                    {t("Квартал")}
                  </Typography>
                  <StyledAsideButtonQuarter
                    onClick={() =>
                      setRangeHandler(
                        DateTime.local(displayedYearStart, 1, 1, {
                          zone: timezone,
                        }),
                        DateTime.local(displayedYearStart, 3, {
                          zone: timezone,
                        }).endOf("month")
                      )
                    }
                  >
                    I
                  </StyledAsideButtonQuarter>
                  <StyledAsideButtonQuarter
                    onClick={() =>
                      setRangeHandler(
                        DateTime.local(displayedYearStart, 4, 1, {
                          zone: timezone,
                        }),
                        DateTime.local(displayedYearStart, 6, {
                          zone: timezone,
                        }).endOf("month")
                      )
                    }
                  >
                    II
                  </StyledAsideButtonQuarter>
                  <StyledAsideButtonQuarter
                    onClick={() =>
                      setRangeHandler(
                        DateTime.local(displayedYearStart, 7, 1, {
                          zone: timezone,
                        }),
                        DateTime.local(displayedYearStart, 9, {
                          zone: timezone,
                        }).endOf("month")
                      )
                    }
                  >
                    III
                  </StyledAsideButtonQuarter>
                  <StyledAsideButtonQuarter
                    onClick={() =>
                      setRangeHandler(
                        DateTime.local(displayedYearStart, 10, 1, {
                          zone: timezone,
                        }),
                        DateTime.local(displayedYearStart, 12, {
                          zone: timezone,
                        }).endOf("month")
                      )
                    }
                  >
                    IV
                  </StyledAsideButtonQuarter>
                </>
              ) : (
                <>
                  <StyledAsideButton
                    onClick={() =>
                      setRangeHandler(
                        DateTime.local().setZone(timezone).minus({ hour: 1 }),
                        DateTime.local().setZone(timezone)
                      )
                    }
                  >
                    {t("За 1 час")}
                  </StyledAsideButton>
                  <StyledAsideButton
                    onClick={() => {
                      setRangeHandler(
                        DateTime.local().setZone(timezone).minus({ hour: 3 }),
                        DateTime.local().setZone(timezone)
                      );
                    }}
                  >
                    {t("За 3 час")}
                  </StyledAsideButton>
                  <StyledAsideButton
                    onClick={() => {
                      setRangeHandler(
                        DateTime.local().setZone(timezone).minus({ hour: 6 }),
                        DateTime.local().setZone(timezone)
                      );
                    }}
                  >
                    {t("За 6 час")}
                  </StyledAsideButton>
                  <StyledAsideButton
                    onClick={() => {
                      setRangeHandler(
                        DateTime.local().setZone(timezone).minus({ hour: 12 }),
                        DateTime.local().setZone(timezone)
                      );
                    }}
                  >
                    {t("За 12 час")}
                  </StyledAsideButton>
                  <StyledAsideButton
                    onClick={() => {
                      setRangeHandler(
                        DateTime.fromObject(
                          { hour: 0, minute: 0 },
                          { zone: timezone }
                        ),
                        DateTime.local().setZone(timezone)
                      );
                    }}
                  >
                    {t("За сегодня")}
                  </StyledAsideButton>
                </>
              )}
            </StyledAside>
            <StyledMain>
              {withSwitch && valueSwitch ? (
                <>
                  <StyledCalendarsWrapper>
                    <CalendarMonth
                      rangeStart={start}
                      rangeEnd={end}
                      onDateSet={handleSelectMonth}
                      decreeseYearHandler={decreeseYearHandler}
                      increeseYearHandler={increeseYearHandler}
                      displayedYear={displayedYearStart}
                    />
                  </StyledCalendarsWrapper>
                </>
              ) : (
                <>
                  <StyledCalendarsWrapper>
                    <Calendar
                      rangeStart={start}
                      rangeEnd={end}
                      minDate={minDate}
                      type="start"
                      onDateSet={handleSelectDate}
                      decreeseMontHandler={decreeseMontHandler}
                      displayedYear={displayedYearStart}
                      displayedMonth={displayedMonthStart}
                    />
                    <Calendar
                      rangeStart={start}
                      rangeEnd={end}
                      minDate={minDate}
                      type="end"
                      onDateSet={handleSelectDate}
                      increeseMonthHandler={increeseMonthHandler}
                      displayedYear={displayedYearEnd}
                      displayedMonth={displayedMonthEnd}
                    />
                  </StyledCalendarsWrapper>
                  <StyledActionPanel>
                    <StyledRangeDisplay>
                      <Box flex alignItems="center">
                        <Typography style={{ width: "80px" }}>
                          {dateFormat(start?.toISO() || null, {
                            format: "date",
                          })}
                        </Typography>
                        {start ? (
                          <>
                            <Box mx={5}>
                              <TimeSelector
                                type="start"
                                rangeStart={start}
                                rangeEnd={end}
                                onChange={handleSelectTimeStart}
                              />
                            </Box>
                            <Typography mr={5}>-</Typography>
                          </>
                        ) : (
                          <></>
                        )}
                        <Typography style={{ width: "80px" }}>
                          {dateFormat(end?.toISO() || null, {
                            format: "date",
                          })}
                        </Typography>
                        {end ? (
                          <Box ml={5}>
                            <TimeSelector
                              type="end"
                              rangeStart={start}
                              rangeEnd={end}
                              onChange={handleSelectTimeEnd}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </StyledRangeDisplay>
                    {!withSwitch ? (
                      <div>
                        <StyledActionPanelButton
                          background={accentColor}
                          onClick={doneHandler}
                          disabled={!start || !end}
                        >
                          {t("Применить")}
                        </StyledActionPanelButton>
                      </div>
                    ) : (
                      <></>
                    )}
                  </StyledActionPanel>
                </>
              )}
              {withSwitch ? (
                <StyledActionPanel>
                  <Box flex alignItems="center" nowrap>
                    <Typography variant="subtitle3" pr={8}>
                      {t("Архив")}
                    </Typography>
                    <Switch
                      small
                      value={!!valueSwitch}
                      onChange={handleChangeSwitch}
                    />
                  </Box>
                  <div>
                    <StyledActionPanelButton
                      background={accentColor}
                      onClick={doneHandler}
                      disabled={!start || !end}
                    >
                      {t("Применить")}
                    </StyledActionPanelButton>
                  </div>
                </StyledActionPanel>
              ) : (
                <></>
              )}
            </StyledMain>
          </StyledMainWrapper>
        </StyledRangeSelectorPopUp>
      )}
      <StyledChildWrapper onClick={handleToglePopUp}>
        {children}
      </StyledChildWrapper>
    </StyledRangeSelectorWrapper>
  );
};

const StyledRangeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (${RESPONSIVE_SIZES.md}) {
    flex: 1;
  }
`;

const StyledMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (${RESPONSIVE_SIZES.md}) {
    flex-direction: column;
  }
`;

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCalendarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (${RESPONSIVE_SIZES.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledAside = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledAsideButton = styled.button`
  cursor: pointer;
  padding: 5px 0;
  border: none;
  background-color: white;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  @media (${RESPONSIVE_SIZES.md}) {
    padding: 5px 20px;
  }
`;

const StyledAsideButtonQuarter = styled(StyledAsideButton)`
  padding: 5px 10px 5px 0;
`;

const StyledRangeSelectorPopUp = styled.div`
  position: absolute;
  top: 52px;
  max-width: 525px;
  ${({ right }: { right: boolean }) => (right ? "right: 0" : "left: 0")};
  background: white;
  padding: 20px;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.04);
  display: flex;
  z-index: 110;
  @media (${RESPONSIVE_SIZES.md}) {
    max-width: 100%;
  }
`;

const StyledChildWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  justify-content: space-around;
`;

const StyledRangeDisplay = styled.div`
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  @media (${RESPONSIVE_SIZES.md}) {
    margin: 20px 0;
  }
`;

const StyledActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (${RESPONSIVE_SIZES.md}) {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledActionPanelButton = styled.button`
  background: ${({ background }: { background: string }) => background};
  color: #ffffff;
  border: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  padding: 8px 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
