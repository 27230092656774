import React, { useState } from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { getColors } from "utils/getColors";
import { ReactComponent as Calendar } from "utils/img/calendar.svg";
import { ReactComponent as Close } from "utils/img/close.svg";
import { hexToRGB } from "utils/mixColors";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useDateFormatter } from "hooks/useDateFormatter";
import { DateInputSelector } from "./DateInputSelector";

interface DateTimePickerProps {
  className?: string;
  placeholder?: string;
  label?: string;
  value: DateTime | null;
  onChange: (value: string) => void;
  error?: boolean | string;
  helperText?: string | boolean;
  onFocus?: () => void;
  oldDate?: boolean;
}

export const DateTimePicker = ({
  className,
  placeholder,
  label,
  value,
  onChange,
  error,
  helperText,
  onFocus,
  oldDate,
}: DateTimePickerProps): React.ReactElement => {
  const [focus, setFocus] = useState<boolean>(false);
  const { accentColor } = getColors();
  const { dateFormat } = useDateFormatter();

  return (
    <StyledDataSelector className={className}>
      <DateInputSelector
        date={value}
        onChange={(date: DateTime | null) => {
          if (date) {
            onChange(date.toISO());
          }
        }}
        oldDate={!!oldDate}
      >
        <StyledRelative>
          <StyledFieldset
            error={!!error}
            bordercolor={accentColor}
            focus={focus}
          >
            <StyledLabel empty={!label}>{label || ""}</StyledLabel>
            <StyledCalendar />
            <StyledTextInput
              readOnly
              bordercolor={accentColor}
              placeholder={placeholder}
              value={
                value
                  ? `${dateFormat(
                      value.toISO(),
                      oldDate ? { format: "date" } : {}
                    )}`
                  : ""
              }
              onMouseDown={() => {
                setFocus(true);
                if (onFocus) {
                  onFocus();
                }
              }}
              onBlur={() => setFocus(false)}
            />
          </StyledFieldset>
        </StyledRelative>
        {value ? (
          <StyledClose
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChange("");
            }}
          />
        ) : null}
      </DateInputSelector>
      {helperText ? (
        <StyledHelperText error={!!error}>{helperText}</StyledHelperText>
      ) : null}
    </StyledDataSelector>
  );
};

const StyledFieldset = styled.fieldset<{
  error?: boolean;
  bordercolor: string;
  focus?: boolean;
}>`
  border: none;
  border: 1px solid ${(props) => (props.error ? "#D00025" : "#B9BFC8")};
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  &:hover {
    border: 1px solid
      ${(props) => (props.error ? "#D00025" : hexToRGB(props.bordercolor, 0.4))};
    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }
  ${(props) =>
    props.focus
      ? `
          border: 1px solid
            ${props.error ? "#D00025" : props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
`;

const StyledLabel = styled.legend<{ empty: boolean }>`
  font-style: normal;
  font-weight: 400;
  color: #19202e;
  max-width: calc(133% - 30px);
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  ${(props) =>
    props.empty
      ? `
          padding-left: 0;
          padding-right: 0;
        `
      : ""};
`;
const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { error: boolean }) =>
    props.error ? "#D00025" : "#727272"};
`;
const StyledRelative = styled.div`
  position: relative;
  width: 100%;
`;
const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 14px;
  left: 14px;
`;
const StyledClose = styled(Close)`
  position: absolute;
  cursor: pointer;
  top: 14px;
  right: 10px;
  padding: 6px;
  z-index: 100;
`;

const StyledTextInput = styled.input<{ bordercolor: string }>`
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 6.5px;
  width: 100%;
  color: #19202e;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledDataSelector = styled.div`
  @media (${RESPONSIVE_SIZES.md}) {
    width: 100%;
  }
`;
