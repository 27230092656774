import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { includes } from "lodash";
import * as yup from "yup";
import { useLazyGetServicesCommissionsQuery } from "api/baseAPI/services";
import { useGetUsersMeСustomPermissionsQuery } from "api/baseAPI/user";
import {
  useBinancePayTopUpMutation,
  useGetWalletQuery,
} from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import { Button } from "UI/Button";
import { Divider } from "UI/Divider";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Modal } from "UI/Modal";
import { Typography } from "UI/Typography";
import { getFirstService } from "utils/helpers";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import { setSelectedWallet } from "reducers/basicSlice";
import { BinancePayModalContent } from "./parts/BinanceModalContent";
import { WalletInfo } from "./parts/WalletInfo";

export type PayUrls = {
  qr_code_content: string;
  qr_code_deeplink: string;
  qr_code_img_link: string;
  link_universal_url: string;
} | null;

export const BinancePayTopUp = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Пополнение"), ""],
    ["Binance Pay", ""],
  ];

  const dispatch = useDispatch();
  const selectedPointManager = useSelectedPointManager();
  const selectedWallet = useSelectedWalletManager();
  const { amountFormat } = useNumberFormatter();
  const { data: walletData } = useGetWalletQuery({
    id: selectedWallet.id,
  });

  const getСustomPermissions = useGetUsersMeСustomPermissionsQuery();
  const [isShowPayModal, setIsShowPayModal] = useState<boolean>(false);
  const [payUrls, setPayUrls] = useState<PayUrls | null>(null);

  const [commissions, setCommissions] = useState<{
    customer: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
    point: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
    provider: {
      fix: number | null;
      max: number | null;
      min: number | null;
      percent: number | null;
    };
  } | null>(null);

  const [getServiceCommissions, { isError: isErrorServiceCommissions }] =
    useLazyGetServicesCommissionsQuery();

  useEffect(() => {
    if (
      walletData?.currency &&
      selectedPointManager.service_config?.binance_top_up?.[
        walletData?.currency
      ] &&
      selectedPointManager.point_id
    ) {
      if (walletData?.currency) {
        getServiceCommissions({
          serviceId: getFirstService(
            selectedPointManager.service_config?.binance_top_up[
              walletData?.currency
            ]
          ),
          pointId: selectedPointManager.point_id,
        })
          .unwrap()
          .then((data) => setCommissions(data));
      }
    }
  }, [
    walletData?.currency,
    selectedPointManager.service_config?.binance_top_up,
    selectedPointManager.point_id,
  ]);

  const [binancePayTopUp] = useBinancePayTopUpMutation();

  const form = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      if (walletData?.currency) {
        binancePayTopUp({
          walletId: selectedWallet.id,
          pointId: selectedPointManager.point.id,
          currency: walletData.currency,
          amount: Number(values.amount),
          serviceId: getFirstService(
            selectedPointManager.service_config.binance_top_up?.[
              walletData?.currency
            ]
          ),
        })
          .unwrap()
          .then((data) => {
            setIsShowPayModal(true);
            const payUrlsLinks = data?.response?.response?.result;
            if (payUrlsLinks) {
              setPayUrls(payUrlsLinks);
            } else {
              setPayUrls(null);
            }
          })
          .catch(() => {
            // Show error notification
          })
          .finally(() => {
            setSubmitting(false);
            setIsShowPayModal(true);
          });
      }
    },
    validationSchema: yup.object().shape({
      amount: yup.number().required(t("Обязательное поле")),
    }),
  });

  useTranslateFormErrors(form);

  const chargedCommission = useMemo(() => {
    if (commissions?.point && form.values.amount) {
      const pointPercent = commissions.point.percent || 0;
      const pointFix = commissions.point.fix || 0;

      const percent = (Number(form.values.amount) * -pointPercent) / 100;
      return (-pointFix + percent).toFixed(2);
    }
    return "0.00";
  }, [commissions, form.values.amount]);

  const handleSelectWallet = (item: { id: number; name: string }) => {
    dispatch(setSelectedWallet(item));
  };

  return (
    <Layout title={t("Пополнение")} breadCrumbs={breadCrumbs}>
      <Grid
        container
        style={{
          background: "#ffffff",
          padding: "24px",
        }}
        hSpace={24}
        vSpace={12}
      >
        <Grid item sm={12} lg={8}>
          <WalletInfo
            selectedWallet={selectedWallet}
            onChangeSelectedWallet={handleSelectWallet}
          />
        </Grid>
        <Grid item responsive={{ sm: { hidden: true }, lg: { hidden: false } }}>
          <Divider />
        </Grid>
        <Grid item sm={12} lg="fill">
          <Grid
            container
            vSpace={16}
            responsive={{ sm: { direction: "column" } }}
          >
            <Grid item mb={15} sm="fill">
              <Typography variant="h6">{t("Сумма пополнения")}</Typography>
            </Grid>
            <Grid item sm="fill">
              <TextInput
                type="text"
                value={numberForInput(form.values.amount)}
                onChange={(val) => {
                  form.setFieldValue(
                    "amount",
                    handleChangeNumber(val.replace(/[^0-9.]/g, ""))
                  );
                }}
                size="small"
                helperText={
                  form.touched.amount && form.errors.amount
                    ? form.errors.amount
                    : `${t("Комиссия")} ${walletData?.currency} ${amountFormat(
                        chargedCommission
                      )}`
                }
                error={form.touched.amount && Boolean(form.errors.amount)}
              />
              <Button
                mt={10}
                onClick={() => form.submitForm()}
                disabled={
                  form.isSubmitting ||
                  isErrorServiceCommissions ||
                  !includes(getСustomPermissions.data, "binance_pay_top_up")
                }
                fullwidth
              >
                {t("Пополнить")}
              </Button>
              <Modal
                title={t("Адрес оплаты сформирован")}
                open={isShowPayModal && payUrls !== null}
                width={552}
                onClose={() => setIsShowPayModal(false)}
              >
                <BinancePayModalContent payUrls={payUrls} />
              </Modal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
