import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { includes } from "lodash";
import * as Yup from "yup";
import {
  useCreateContactWalletMutation,
  useGetContactWalletsQuery,
} from "api/baseAPI/contactWallets";
import { useLazyGetCurrencyRateQuery } from "api/baseAPI/currencyRates";
import { useGetUsersMeСustomPermissionsQuery } from "api/baseAPI/user";
import { useCalculateMutation, useTransferMutation } from "api/baseAPI/w2w";
import {
  useGetWalletQuery,
  useLazyGetWalletQuery,
  useLazyWalletCheckQuery,
} from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Divider } from "UI/Divider";
import { Switch } from "UI/Form/Switch";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { handleChangeNumber, numberForInput } from "utils/numbers";
import { getCurrencyIcon } from "utils/useStyle";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import {
  useSelectedPointManager,
  useSelectedWalletManager,
} from "hooks/useSelectedEntity";
import { setSelectedWallet } from "reducers/basicSlice";
import { ArrowInSquare } from "./parts/ArrowInSquare";
import { ReceiverById } from "./parts/ReceiverById";
import { ReceiverByWallet } from "./parts/ReceiverByWallet";
import { SuccessModal } from "./parts/SuccessModal";
import { SummaryCard } from "./parts/SummaryCard";
import { WalletCard } from "./parts/WalletCard";
import { WalletInfo } from "./parts/WalletInfo";

export type TransferFormValues = {
  amount: string;
  payCommission: boolean;
  receiverWalletId: string;
  walletName: string;
  comment: string;
  saveWallet: boolean;
};

export const W2w = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Кошельки"), ""],
    [t("W2W Переводы"), ""],
  ];

  const dispatch = useDispatch();
  const pointManager = useSelectedPointManager();
  const selectedWallet = useSelectedWalletManager();
  const { amountFormat } = useNumberFormatter();

  const { data: walletData } = useGetWalletQuery({
    id: selectedWallet.id,
  });
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [isBlockSubmit, setIsBlockSubmit] = useState<boolean>(false);
  const [transferById, setTransferById] = useState<boolean>(true);

  const getСustomPermissions = useGetUsersMeСustomPermissionsQuery();
  const [transfer, transferMeta] = useTransferMutation();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [transId, setTransId] = useState<string>("");
  const [, setSuccessAmount] = useState<string>("");

  const [createContactWallet] = useCreateContactWalletMutation();

  const [getWallet] = useLazyGetWalletQuery();
  const [calculateCommission] = useCalculateMutation();
  const [walletCheck] = useLazyWalletCheckQuery();

  const form = useFormik<TransferFormValues>({
    initialValues: {
      amount: "",
      payCommission: true,
      receiverWalletId: "",
      walletName: "",
      comment: "",
      saveWallet: false,
    },
    onSubmit: (values, { setSubmitting }) => {
      if (!isPreview) {
        if (form.values.amount && form.values.receiverWalletId) {
          setIsBlockSubmit(true);
          setCommission(0);
          setCommissionCurrency("");

          calculateCommission({
            sender_wallet_id: selectedWallet.id,
            receiver_wallet_id: Number(form.values.receiverWalletId),
            sender_amount: Number(form.values.amount),
          })
            .unwrap()
            .then((data) => {
              if (data?.success) {
                setCommission(data.result.commission_amount);
                setCommissionCurrency(data.result.commission_currency);
                setIsPreview(true);
              } else {
                form.setFieldError("amount", " ");
                form.setFieldError("receiverWalletId", " ");
                toast.error(data?.message);
              }
            })
            .catch((res) => {
              if (
                res.status === 400 &&
                res.data[0]?.includes("does not exist")
              ) {
                form.setFieldError("receiverWalletId", t("Кошелек не найден"));
              }

              if (
                res.status === 400 &&
                Object.keys(res.data).includes("sender_amount")
              ) {
                form.setFieldError("amount", "");
                toast.error(res.data?.sender_amount.join(" "));
              }
            })
            .finally(() => {
              setIsBlockSubmit(false);
            });
        }
      } else {
        transfer({
          sender_wallet_id: selectedWallet.id,
          receiver_wallet_id: Number(values.receiverWalletId),
          sender_amount: Number(values.amount),
          commission_on_sender: values.payCommission,
        })
          .unwrap()
          .then((data) => {
            getWallet({ id: selectedWallet.id });
            setShowSuccessModal(true);
            setTransId(String(data.response.id));
            setSuccessAmount(String(values.amount));
            if (form.values.saveWallet && receiverCurrency) {
              createContactWallet({
                account_wallet_id: Number(form.values.receiverWalletId),
                currency: receiverCurrency,
                name: form.values.walletName,
                point_manager: pointManager.id,
              })
                .unwrap()
                .then()
                .catch((err) => console.error("Save contact wallet", err));
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .typeError(t("Введите число"))
        .min(1, t("Введите число больше 0"))
        .required(t("Поле обязательно для заполнения")),
      receiverWalletId: Yup.number()
        .notOneOf([selectedWallet.id], t("Нельзя перевести на этот кошелек"))
        .required(t("Поле обязательно для заполнения")),
      walletName: Yup.string()
        .max(100, t("Максимум 100 символов"))
        .when("saveWallet", {
          is: (val: boolean) => val,
          then: Yup.string().required(t("Поле обязательно для заполнения")),
        }),
      comment: Yup.string().max(100, t("Максимум 100 символов")),
    }),
  });

  useTranslateFormErrors(form);

  useEffect(() => {
    form.resetForm();
  }, [transferById]);

  const { data: contactWalletsData } = useGetContactWalletsQuery();

  const receiverWallet =
    contactWalletsData?.results.find(
      (wallet) =>
        Number(wallet.account_wallet.id) ===
        Number(form.values.receiverWalletId)
    ) || null;

  const handleSelectWallet = (item: { id: number; name: string }) => {
    dispatch(setSelectedWallet(item));
  };

  const [receiverCurrency, setReceiverCurrency] = useState<null | string>(null);

  useEffect(() => {
    if (form.values.receiverWalletId) {
      walletCheck({ id: Number(form.values.receiverWalletId) })
        .unwrap()
        .then((data) => setReceiverCurrency(data.currency))
        .catch(() => setReceiverCurrency(null));
    } else {
      setReceiverCurrency(null);
    }
  }, [form.values.receiverWalletId]);

  const [rate, setRate] = useState<null | number>(null);
  const [getCurrencyRate] = useLazyGetCurrencyRateQuery();

  useEffect(() => {
    if (receiverCurrency === walletData?.currency) {
      setRate(1);
    } else if (receiverCurrency) {
      getCurrencyRate({
        id: `${walletData?.currency}:${receiverCurrency}`,
      })
        .unwrap()
        .then((data) => setRate(Number(data.rate)))
        .catch(() => {
          setRate(null);
        });
    } else {
      setRate(null);
    }
  }, [receiverCurrency]);

  const amountRate = (() => {
    if (!rate) return 0;
    if (rate === 0) return form.values.amount;
    return (Number(form.values.amount) * rate).toFixed(2);
  })();

  const [commission, setCommission] = useState<number | 0>(0);
  const [commissionCurrency, setCommissionCurrency] = useState<string>("");

  return (
    <Layout title={t("W2W Переводы")} breadCrumbs={breadCrumbs}>
      {!isPreview ? (
        <Grid container>
          <Grid item sm={12} xl={4}>
            <Grid
              container
              style={{
                background: "#ffffff",
                padding: "24px",
              }}
              hSpace={24}
              vSpace={12}
              responsive={{ sm: { direction: "column" } }}
            >
              <Grid item sm={12} md={8}>
                <Typography mb={15} variant="h6">
                  {t("Кошелек отправителя")}
                </Typography>
                <WalletInfo
                  selectedWallet={selectedWallet}
                  onChangeSelectedWallet={handleSelectWallet}
                />
              </Grid>
              <Grid item sm="fill" m={0}>
                <Divider smDirection="horizontal" />
              </Grid>
              <Grid item sm={12} md="fill">
                <Grid
                  container
                  vSpace={16}
                  responsive={{ sm: { direction: "column" } }}
                >
                  <Grid item mb={15} sm="fill">
                    <Typography variant="h6">{t("Сумма перевода")}</Typography>
                  </Grid>
                  <Grid item sm="fill">
                    <TextInput
                      type="text"
                      value={numberForInput(form.values.amount)}
                      onChange={(val) => {
                        const value = handleChangeNumber(
                          val.replace(/^[0]+/g, "").replace(/[^0-9.]/g, "")
                        );
                        form.setFieldValue("amount", value);
                      }}
                      placeholder={t("Сумма")}
                      size="small"
                      style={{ marginBottom: "10px" }}
                      error={form.touched.amount && Boolean(form.errors.amount)}
                      helperText={form.touched.amount && form.errors.amount}
                    />
                    <Box p={12} style={{ backgroundColor: "#EAEFF7" }} mb={15}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle3" mb={20}>
                            {t("Комиссия")}
                          </Typography>
                          <Box flex>
                            {getCurrencyIcon(commissionCurrency || "")}
                            {amountFormat(commission || 0)}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          flex
                          direction="column"
                          alignItems="flex-end"
                        >
                          <Typography variant="subtitle3" mb={15}>
                            {t("Оплатить комиссию")}
                          </Typography>
                          <Switch
                            value={form.values.payCommission}
                            onChange={(value) =>
                              form.setFieldValue("payCommission", value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Button
                      type="button"
                      onClick={() => form.handleSubmit()}
                      disabled={isBlockSubmit}
                      fullwidth
                    >
                      {t("Далее")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xl={8}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              responsive={{ sm: { m: 16 }, md: { m: 24 } }}
            >
              <Grid item sm={12} md={5} flex alignItems="center">
                <Typography
                  variant="h6"
                  responsive={{ sm: { mb: 24 }, md: { mb: 0 } }}
                >
                  {t("Введите данные получателя")}
                </Typography>
              </Grid>
              <Grid item sm={12} md={7}>
                <ButtonSwitch
                  firstTitle={t("Перевод по ID кошелька")}
                  secondTitle={t("Сохр. кошельки")}
                  value={transferById}
                  onChange={() => setTransferById(!transferById)}
                />
              </Grid>
            </Grid>

            {transferById ? (
              <ReceiverById
                form={form}
                currency={{
                  from: walletData?.currency || null,
                  to: receiverCurrency,
                }}
                amountRate={amountRate}
                rate={rate}
              />
            ) : (
              <ReceiverByWallet
                form={form}
                currency={{
                  from: walletData?.currency || null,
                  to: receiverCurrency,
                }}
                amountRate={amountRate}
                rate={rate}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item sm={12} md={8}>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="body"
                  p={24}
                  fontSize={16}
                  fontWeight="bold"
                >
                  {t("Введите данные получателя")}
                </Typography>
              </Grid>
              <Grid item mx={24}>
                <Grid
                  container
                  style={{ background: "white" }}
                  p={24}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="body" mb={24} fontWeight="bold">
                      {t("Кошелек отправителя")}
                    </Typography>
                    <WalletCard
                      walletName={`${selectedWallet.id} | ${selectedWallet.name}`}
                      currency={walletData?.currency || ""}
                    />
                  </Grid>
                  <Grid item responsive={{ sm: { my: 15 }, md: { py: 80 } }}>
                    <ArrowInSquare />
                  </Grid>
                  <Grid item>
                    <Typography variant="body" mb={24} fontWeight="bold">
                      {t("Получатель")}
                    </Typography>
                    <WalletCard
                      walletName={
                        receiverWallet
                          ? `${receiverWallet.account_wallet.id} | ${receiverWallet.name}`
                          : form.values.receiverWalletId
                      }
                      currency={receiverCurrency || ""}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} style={{ minHeight: "calc(100vh - 62px)" }}>
            <Grid
              container
              direction="column"
              style={{ minHeight: "100%", background: "white" }}
              justifyContent="space-between"
            >
              <Grid item>
                <SummaryCard
                  sum={`${form.values.amount}`}
                  sumByRate={`${amountRate}`}
                  senderCurrency={walletData?.currency || ""}
                  receiverCurrency={receiverCurrency || ""}
                  rate={`${rate}`}
                  commission={String(commission)}
                  commissionCurrency={commissionCurrency || ""}
                  comment={form.values.comment}
                />
              </Grid>
              <Grid item>
                <Box flex justifyContent="space-around" p={24}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsPreview(false)}
                  >
                    {t("Назад")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => form.handleSubmit()}
                    disabled={
                      !includes(
                        getСustomPermissions.data,
                        "wallet_to_wallet_transfer"
                      ) || transferMeta.isLoading
                    }
                  >
                    {t("Отправить")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {showSuccessModal ? (
        <SuccessModal
          title={t("Перевод на сумму {{amount}} успешно выполнен!", {
            amount: `${form.values.amount} ${
              (selectedWallet as any).currency || ""
            }`,
          })}
          buttonText={t("В раздел кошельки")}
          handleClick={() => {
            form.resetForm();
            setShowSuccessModal(false);
            setIsPreview(false);
          }}
          transId={transId}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};
