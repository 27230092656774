import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import * as htmlToImage from "html-to-image";
import { includes } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import styled from "styled-components";
import {
  SocInvoiceDataQueryParams,
  useDeleteSocInvoiceDataMutation,
  useGetSocInvoiceDatasQuery,
} from "api/baseAPI/socInvoice";
import { useGetUsersMeInvoicingPermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  CopyCell,
  DataItem,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as DownloadIcon } from "utils/img/download.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ProductsTable } from "./parts/ProductsTable";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

export const SocInvoiceDatas = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные сервисы"), ""],
    [t("Выставление счета"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Отправитель"),
    t("Получатель"),
    t("Кошелек"),
    t("Сумма"),
    t("Кол-во товара"),
  ];

  const [openId, setOpenId] = useState<number>(0);
  const selectedPointManager = useSelectedPointManager();
  const getInvoicingPermissions = useGetUsersMeInvoicingPermissionsQuery();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<SocInvoiceDataQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch, error } = useGetSocInvoiceDatasQuery({
    ...queryParams,
    page__point: `${selectedPointManager?.point_id || ""}`,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const [deleteSocInvoiceData, deleteSocInvoiceDataMeta] =
    useDeleteSocInvoiceDataMutation();

  useSuccessNotification([deleteSocInvoiceDataMeta]);
  useErrorNotification([deleteSocInvoiceDataMeta]);

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };
  const handleDownload = (id: string) => {
    const node = document.getElementById(id);
    if (node) {
      htmlToImage
        .toBlob(node, {
          backgroundColor: "#ffffff",
          style: {
            paddingTop: "100px",
            paddingBottom: "100px",
          },
          width: 430,
          height: 600,
          canvasWidth: 400,
          canvasHeight: 600,
          pixelRatio: 2,
        })
        .then((blob) => {
          if (window.saveAs) {
            window.saveAs(blob as Blob, "qr.png");
          } else {
            saveAs.saveAs(blob as Blob, "qr.png");
          }
        })
        .catch((err) => {
          console.error("oops, something went wrong!", err);
        });
    }
  };

  return (
    <Layout title={t("Выставление счета")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Создать счет оплаты")}
              {...(includes(getInvoicingPermissions.data, "add_page")
                ? {
                    createPath: "/services/soc-invoice-data/create",
                  }
                : {})}
              refetch={refetch}
            />
          }
          count={data && !error ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && data.results.length > 0 && !error
            ? data.results.map((row) => (
                <React.Fragment key={row.id}>
                  <StyledRow>
                    <StyledCell>
                      <Box flex alignItems="center" nowrap>
                        <IconCellOpen
                          open={openId === row.id}
                          onClick={() => handleOpen(row.id)}
                        />
                        {row.id}
                      </Box>
                    </StyledCell>
                    <StyledCell>{row.sender_full_name}</StyledCell>
                    <StyledCell>{row.receiver_full_name}</StyledCell>
                    <StyledCell>
                      {`${row?.wallet?.id} | ${row?.wallet?.name}`}
                    </StyledCell>
                    <StyledCell>
                      <Box flex justifyContent="center" nowrap>
                        {getCurrencyIcon(row.page?.amount_currency)}
                        {amountFormat(row?.page?.fixed_amount)}
                      </Box>
                    </StyledCell>
                    <StyledCell>{row.products?.length}</StyledCell>
                    <StyledCell key="actions" align="right">
                      <TableRowActions
                        actions={[
                          ...(includes(
                            getInvoicingPermissions.data,
                            "delete_socinvoicedata"
                          )
                            ? [
                                <MenuItemAction
                                  key={3}
                                  onClick={() =>
                                    deleteSocInvoiceData({ id: row.id })
                                  }
                                >
                                  <DeleteIcon /> &nbsp;{t("Удалить")}
                                </MenuItemAction>,
                              ]
                            : []),
                        ]}
                      />
                    </StyledCell>
                  </StyledRow>
                  {openId === row.id && (
                    <StyledRow>
                      <td colSpan={7}>
                        <Grid container pl={8} py={16} hSpace={16} vSpace={16}>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Файл QR-кода")}
                              content={
                                row?.page?.pay_url ? (
                                  <>
                                    <Box
                                      onClick={() =>
                                        handleDownload(`qr-code-${row.id}`)
                                      }
                                      flex
                                      alignItems="center"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <IconColor color="#2C60B0">
                                        <DownloadIcon />
                                      </IconColor>
                                      <>&nbsp;</>
                                      <Typography
                                        variant="subtitle3"
                                        style={{ color: "#2C60B0" }}
                                      >{`${t(
                                        "Скачать"
                                      ).toLocaleUpperCase()} PNG`}</Typography>
                                    </Box>
                                    <div style={{ display: "none" }}>
                                      <div id={`qr-code-${row.id}`}>
                                        <Typography
                                          variant="h5"
                                          mb={25}
                                          textAlign="center"
                                        >
                                          {t("Сканируй меня!")}
                                        </Typography>
                                        <StyledQrPlaceholderWrapper>
                                          <QRCodeSVG
                                            size={214}
                                            value={row?.page?.pay_url}
                                          />
                                        </StyledQrPlaceholderWrapper>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Email отправителя")}
                              content={
                                row?.sender_email ? (
                                  <CopyCell content={row?.sender_email} />
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Телефон отправителя")}
                              content={
                                row?.sender_phone_number ? (
                                  <CopyCell
                                    content={row?.sender_phone_number}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Ссылка на платеж")}
                              content={
                                row?.page?.pay_url ? (
                                  <CopyCell
                                    content={row?.page?.pay_url}
                                    text={`${row?.page?.pay_url.substring(
                                      0,
                                      40
                                    )}...`}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Email получателя")}
                              content={
                                row?.receiver_email ? (
                                  <CopyCell content={row?.receiver_email} />
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={4}>
                            <DataItem
                              text={t("Телефон получателя")}
                              content={
                                row?.receiver_phone_number ? (
                                  <CopyCell
                                    content={row?.receiver_phone_number}
                                  />
                                ) : (
                                  ""
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Box p={16} pt={4}>
                          <ProductsTable products={row?.products} />
                        </Box>
                      </td>
                    </StyledRow>
                  )}
                </React.Fragment>
              ))
            : null}
        </Table>
      </Box>
    </Layout>
  );
};

const IconColor = styled.div`
  svg {
    width: 12px !important;
    height: 12px !important;

    path {
      fill: ${(props: { color: string }) => props.color} !important;
    }
  }
`;
const StyledQrPlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
