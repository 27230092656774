import { useTranslation } from "react-i18next";

export const useNumberFormatter = () => {
  const { i18n } = useTranslation();

  const amountFormat = (amount: number | string, charsAfterPoint?: number) => {
    const formattedInteger = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: charsAfterPoint || 2,
      maximumFractionDigits: charsAfterPoint || 2,
    }).format(Number(amount));
    return formattedInteger;
  };

  return { amountFormat };
};
