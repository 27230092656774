import { useTranslation } from "react-i18next";

export const useDateFormatter = () => {
  const { i18n } = useTranslation();

  const dateFormat = (
    dateString: string | null,
    options?: {
      format?: "date" | "time" | "date-time"; // default date-time
      intlOptions?: Intl.DateTimeFormatOptions;
    }
  ): string => {
    if (dateString === null || !dateString) {
      return "";
    }

    if (options?.format === "date") {
      const date = new Intl.DateTimeFormat(i18n.language, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      const d = new Date(dateString);
      return date.format(d);
    }

    if (options?.format === "time") {
      const date = new Intl.DateTimeFormat(i18n.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: i18n.language === "en",
      });
      const d = new Date(dateString);
      return date.format(d);
    }

    const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: i18n.language === "en",
    });

    const date = new Date(dateString);
    return dateTimeFormat.format(date);
  };

  return { dateFormat };
};
