import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetWalletQuery } from "api/baseAPI/wallets";
import { WalletSelectWidget } from "components/widgets/WalletSelectWidget";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { ReactComponent as ClockIcon } from "utils/img/clock.svg";
import { ReactComponent as FileIcon } from "utils/img/file.svg";
import { ReactComponent as RhombusIcon } from "utils/img/rhombus.svg";
import { ReactComponent as UserIcon } from "utils/img/user.svg";
import { useCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";

export const WalletInfo = ({
  selectedWallet,
  onChangeSelectedWallet,
}: {
  selectedWallet: { id: number; name: string };
  onChangeSelectedWallet: (wallet: { id: number; name: string }) => void;
}) => {
  const { t } = useTranslation();
  const { data: walletData } = useGetWalletQuery({
    id: selectedWallet.id,
  });
  const currencyIcon = useCurrencyIcon(walletData ? walletData.currency : "");
  const selectedPointManager = useSelectedPointManager();
  const { amountFormat } = useNumberFormatter();

  return (
    <Grid container hSpace={24} vSpace={24}>
      <Grid item sm="fill">
        <WalletSelectWidget
          selectedWallet={selectedWallet}
          available_wallets={selectedPointManager?.available_wallets}
          onChange={onChangeSelectedWallet}
          walletData={walletData}
        />
      </Grid>
      <Grid item sm="content">
        <Grid
          container
          hSpace={24}
          vSpace={24}
          responsive={{ sm: { direction: "column" }, md: { direction: "row" } }}
        >
          <Grid item sm={12}>
            <Grid container>
              <Grid item>
                <StyledWrapperIcon background="rgb(83, 171, 105, 0.2)">
                  <UserIcon />
                </StyledWrapperIcon>
              </Grid>
              <Grid item>
                <StyledWrapperTextIcon>
                  <TypographySubtitle3 variant="subtitle3">
                    {t("Собственные средства")}
                  </TypographySubtitle3>
                  <StyledElseBalance>
                    {currencyIcon}
                    {amountFormat(walletData?.balance || "")}
                  </StyledElseBalance>
                </StyledWrapperTextIcon>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container>
              <Grid item>
                <StyledWrapperIcon background="rgb(241, 205, 76, 0.2)">
                  <FileIcon />
                </StyledWrapperIcon>
              </Grid>
              <Grid item>
                <StyledWrapperTextIcon>
                  <TypographySubtitle3 variant="subtitle3">
                    {t("Сумма резерва")}
                  </TypographySubtitle3>
                  <StyledElseBalance>
                    {currencyIcon}
                    {amountFormat(walletData?.rolling_reserve_amount || "")}
                  </StyledElseBalance>
                </StyledWrapperTextIcon>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container>
              <Grid item>
                <StyledWrapperIcon background="rgba(64, 137, 247, 0.2)">
                  <ClockIcon />
                </StyledWrapperIcon>
              </Grid>
              <Grid item>
                <StyledWrapperTextIcon>
                  <TypographySubtitle3 variant="subtitle3">
                    {t("Холд")}
                  </TypographySubtitle3>
                  <StyledElseBalance>
                    {currencyIcon}
                    {amountFormat(walletData?.balance_hold || "")}
                  </StyledElseBalance>
                </StyledWrapperTextIcon>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Grid container>
              <Grid item>
                <StyledWrapperIcon background="rgba(226, 42, 42, 0.1)">
                  <RhombusIcon />
                </StyledWrapperIcon>
              </Grid>
              <Grid item>
                <StyledWrapperTextIcon>
                  <TypographySubtitle3 variant="subtitle3">
                    {t("Овердрафт")}
                  </TypographySubtitle3>
                  <StyledElseBalance>
                    {currencyIcon}
                    {amountFormat(
                      walletData?.balance_min
                        ? Number(walletData?.balance_min) * -1
                        : ""
                    )}
                  </StyledElseBalance>
                </StyledWrapperTextIcon>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const TypographySubtitle3 = styled(Typography)`
  color: #374258 !important;
  margin-bottom: 8px !important;
`;
const StyledWrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  background: ${(props: { background: string }) => props.background};
`;
const StyledWrapperTextIcon = styled.div`
  margin-left: 12px;
`;
const StyledElseBalance = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #19202e;
  display: flex;
`;
