import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { PIXPayoutForm } from "pages/payout/logic/forms/PIXPayoutForm";
import { ReactComponent as PixIcon } from "utils/img/pix.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class PixPayoutMethod extends BasePayoutMethod implements IPayoutMethod {
  slug: string = "pix";

  kind: PayoutKind = PayoutKinds.PIX;

  name: string = "PIX";

  mobileName: string = this.name;

  icon: ReactElement = (<PixIcon />);

  filenameExample = "sample-pix";

  jsonExample = [
    {
      "Ключ PIX": "some@example.com",
      Сумма: 23.33,
      Примечание: "note 1",
      "Идентификатор получателя": "231.002.999-00",
      Страна: "BRA",
    },
    {
      "Ключ PIX": "some2@example.com",
      Сумма: 50,
      Примечание: "note 2",
      "Идентификатор получателя": "231.002.999-00",
      Страна: "BRA",
    },
    {
      "Ключ PIX": "some3@example.com",
      Сумма: 5050,
      Примечание: "note 3",
      "Идентификатор получателя": "231.002.999-00",
      Страна: "BRA",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.pix_payouts &&
        this.getService(this.pointManager?.service_config?.pix_payouts)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = () => <></>;

  customerAccountValidationSchema = yup.string().required("Введите email");

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <PIXPayoutForm payoutMethod={payoutMethod} />
  );
}
