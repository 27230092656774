import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { includes, isEmpty } from "lodash";
import { DateTime } from "luxon";
import * as Yup from "yup";
import { usePostInvoiceTemplateMutation } from "api/baseAPI/checkoutInvoiceTemplates";
import { Layout } from "components/layouts/Layout";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Modal } from "UI/Modal";
import {
  InvoiceFormValues,
  replaceOut,
  initData,
} from "pages/checkoutInvoice/invoices/InvoiceCreate";
import { FirstStep } from "pages/checkoutInvoice/invoices/parts/FirstStep";
import { ModalSuccess } from "pages/checkoutInvoice/invoices/parts/ModalSuccess";
import { getTimezone } from "utils/getTimezone";
import { useErrorNotification } from "utils/notificationWrappers";
import { useServerErrorsInForm } from "utils/serverErrorsInForm";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { useSelectedPointManager } from "hooks/useSelectedEntity";

export const TemplateCrete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Шаблоны"), "/invoice/templates"],
    [t("Создание шаблона"), ""],
  ];

  const [showSuccess, setShowSuccess] = useState(false);

  const timezone = getTimezone();
  const selectedPointManager = useSelectedPointManager();

  const [createInvoiceTemplate, { error, isError, isLoading }] =
    usePostInvoiceTemplateMutation();

  const handleErrors = (dataErrors: string[]) => {
    if (
      includes(dataErrors, "Point does not have required service connected")
    ) {
      toast.error(t("К точке не подключена требуемая услуга"));
      return;
    }
    if (
      includes(dataErrors, "point and wallet have different account owners")
    ) {
      toast.error(t("У точки и кошелька разные владельцы счетов"));
      return;
    }
    if (includes(dataErrors?.[0], "has different currency from page")) {
      toast.error(t("Валюта кошелька отличается от валюты страницы"));
      return;
    }
    toast.error(t("Ошибка"));
  };

  useEffect(() => {
    if (error && "data" in error && "status" in error && error.status === 400) {
      const dataErrors = error.data as string[];
      handleErrors(dataErrors);
    }
  }, [error]);

  const createForm = useFormik<InvoiceFormValues>({
    initialValues: {
      ...initData,
      active_till: DateTime.local()
        .setZone(timezone)
        .plus({ day: 3 })
        .plus({ hour: 1 })
        .startOf("hour")
        .toISO(),
    },
    onSubmit: (values) => {
      const dataForm = {
        point_id: String(selectedPointManager.point?.id),
        account_id: String(selectedPointManager.user.account_id),

        title: values.title,
        active_till: "",
        locale: values.locale,
        kind: values.kind,

        wallet_id: null,
        service_mapping: values.service_mapping,
        wallet_mapping: values.wallet_mapping,
        external_id: "",

        is_reusable: values.is_reusable,
        amount_currency: values.amount_currency || null,
        fixed_amount: values.fixed_amount || null,
        min_amount: values.min_amount || null,
        default_amount: values.default_amount || null,
        preferred_amounts: [...values.preferred_amounts]?.sort(
          (a: string, b: string) => Number(a) - Number(b)
        ),
        preferred_amounts_logic: values.preferred_amounts_logic,

        additional_fields: values.additional_fields,
        prefilled_fields: values.prefilled_fields,
        description_template: values.description_template
          ? replaceOut(values.description_template, values.additional_fields)
          : "",

        success_amount: 0,
        fail_amount: 0,
        fail_count: 0,
        success_count: 0,
      };
      createInvoiceTemplate(dataForm)
        .unwrap()
        .then(() => setShowSuccess(true))
        .catch((e) => console.warn(e));
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(t("Обязательное поле"))
        .max(128, t("Максимум {{characters}} символов", { characters: 128 })),
      fixed_amount: Yup.string().when("min_amount", {
        is: (val: string) => val,
        then: Yup.string().test(
          "isValidByLuhn",
          t("Сумма должна быть не меньше минимальной"),
          (value: string | undefined): boolean => {
            if (!value) return true;
            return (Number(createForm.values.min_amount) || 0) <= Number(value);
          }
        ),
      }),
      amount_currency: Yup.string().required(t("Обязательное поле")),
      service_mapping: Yup.object().test(
        "isValidLength",
        t("Поле обязательно для заполнения"),
        (value: InvoiceFormValues["service_mapping"]): boolean =>
          !isEmpty(value)
      ),
      preferred_amounts: Yup.array()
        .when("min_amount", {
          is: (val: string) => val,
          then: Yup.array().test(
            "isValidByLuhn",
            t("Сумма должна быть не меньше минимальной"),
            (value: (number | undefined)[] | undefined): boolean => {
              if (value?.length === 0) return true;
              const valid = value?.map(
                (num) =>
                  (Number(createForm.values.min_amount) || 0) <= Number(num)
              );
              return !valid?.includes(false);
            }
          ),
        })
        .test(
          "isValidLength",
          t("Должно быть не меньше 2 вариантов"),
          (value: (number | undefined)[] | undefined): boolean =>
            (value && value?.length === 0) ||
            (!!value && value?.length > 1 && value?.length < 4)
        ),
    }),
  });

  useTranslateFormErrors(createForm);

  useErrorNotification([{ isError }]);
  useServerErrorsInForm(createForm.setFieldError, error);

  return (
    <Layout title={t("Создание шаблона")} breadCrumbs={breadCrumbs}>
      <Box
        px={24}
        py={32}
        flex
        justifyContent="space-between"
        direction="column"
        style={{ minHeight: "calc(100vh - 124px)" }}
      >
        <FirstStep form={createForm} isTemplate />
        <Box flex mt={20}>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            variant="outlined"
            size="small"
            onClick={() => navigate("/invoice/templates")}
          >
            {t("Отмена")}
          </Button>
          <Button
            responsive={{ md: { px: 32 }, sm: { px: 15 } }}
            ml={24}
            variant="contained"
            size="small"
            disabled={isLoading}
            onClick={() => createForm.handleSubmit()}
          >
            {t("Сохранить")}
          </Button>
        </Box>

        <Modal
          open={showSuccess}
          hideHeader
          width={370}
          onClose={() => setShowSuccess(false)}
        >
          <ModalSuccess create isTemplate />
        </Modal>
      </Box>
    </Layout>
  );
};
