import { ReactElement, useEffect, useState } from "react";
import { FormikErrors } from "formik";
import { find } from "lodash";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import { PayoutKind } from "api/baseAPI/payouts";
import { Services } from "api/baseAPI/pointManagers";
import {
  CommissionResponse,
  useLazyGetServicesCommissionsQuery,
} from "api/baseAPI/services";
import { Wallet } from "api/baseAPI/wallets";
import { CustomerAccountInputType } from "pages/payout/logic/forms/components/FormLines";
import {
  Crypto,
  Fiat,
  IPayoutCategory,
} from "pages/payout/logic/payoutsCategory";
import {
  Commissions,
  PayoutData,
  PayoutFormData,
} from "pages/payout/logic/payoutsCreateLogic";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { Subscriber } from "./BasePayoutMethod";
import { BinancePayPayoutMethod } from "./BinancePayPayoutMethod";
import { BinancePayoutMethod } from "./BinancePayoutMethod";
import { CardPayoutMethod } from "./CardPayoutMethod";
import { ExmoPayoutMethod } from "./ExmoPayoutMethod";
import { IbanPayoutMethod } from "./IbanPayoutMethod";
import { KunaCodePayoutMethod } from "./KunaCodePayoutMethod";
import { PhonePayoutMethod } from "./PhonePayoutMethod";
import { PixPayoutMethod } from "./PixPayoutMethod";
import { SettlepayPayoutMethod } from "./SettlepayPayoutMethod";
import { SpeiPayoutMethod } from "./SpeiPayoutMethod";
import { UPIPayoutMethod } from "./UPIPayoutMethod";
import { WhiteBitPayoutMethod } from "./WhiteBitPayoutMethod";

export interface IPayoutMethod {
  slug: string;
  kind: PayoutKind;
  name: string;
  mobileName: string;
  icon: ReactElement;
  form: (pm: IPayoutMethod) => ReactElement;
  isActive: boolean;
  isLoaded: boolean;
  commissionsData: CommissionResponse | null;
  pointManager: ReturnType<typeof useSelectedPointManager> | null;
  serviceIds: Services | null;
  serviceId: number | null;
  pointId: number | null;
  configKey: string | null;
  currency: string | null;
  xlsxExample: string | null;
  jsonExample: Record<string, string | number>[] | null;
  filenameExample: string;
  wallet: Wallet | null;
  category: IPayoutCategory | null;
  customerAccountInput: CustomerAccountInputType;
  customerAccountValidationSchema: RequiredStringSchema<
    string | undefined,
    AnyObject
  >;
  errors: FormikErrors<PayoutFormData> | null;
  values: PayoutFormData | null;
  additional_payout_information?: null | {
    mobile_service_vodafone?: number;
    mobile_service_kyivstar?: number;
    mobile_service_life?: number;
  };

  resetForm: () => void;
  addSubscriber(key: string, s: Subscriber): void;
  notifyFormUpdate(payouts: Record<string, PayoutData>): void;
  notifyFormSaveToTemplate(isSave: boolean): void;
  notifyIsSubmiting(isSubmiting: boolean): void;
  notifyChangeDelay(delay: number): void;
  notifyChangeErrors(status: FormikErrors<PayoutFormData>): void;
  getCommissions(payouts: Record<string, PayoutData>): Commissions;
  handleSubmit(): void;
  handleValidate(): Promise<boolean>;
  handleSaveToTemplate(save: boolean): void;
}

export function usePayouts(
  currency: string,
  wallet: Wallet | null,
  selectedSlug: string,
  selectedService: number | null
): { payoutMethods: IPayoutMethod[]; loading: boolean } {
  const [loading, setLoading] = useState<boolean>(true);

  const selectedPointManager = useSelectedPointManager();

  const fiat = new Fiat();
  const crypto = new Crypto();
  // const inr = new UPI();

  const defaultMethods = [
    new CardPayoutMethod(fiat),
    new IbanPayoutMethod(fiat),
    new PhonePayoutMethod(fiat),
    new SettlepayPayoutMethod(fiat),
    new PixPayoutMethod(fiat),
    new SpeiPayoutMethod(fiat),
    new UPIPayoutMethod(fiat),

    new KunaCodePayoutMethod(crypto),
    new BinancePayoutMethod(crypto),
    new BinancePayPayoutMethod(crypto),
    new ExmoPayoutMethod(crypto),
    new WhiteBitPayoutMethod(crypto),
  ];

  const [payoutMethods, setPayoutMethods] =
    useState<IPayoutMethod[]>(defaultMethods);

  const [getServiceCommissions] = useLazyGetServicesCommissionsQuery();

  useEffect(() => {
    if (!wallet) {
      return;
    }

    setLoading(true);

    for (const payoutMethod of defaultMethods) {
      payoutMethod.pointManager = selectedPointManager;
      payoutMethod.currency = currency;
      payoutMethod.wallet = wallet;

      if (payoutMethod.slug === "phone") {
        payoutMethod.additional_payout_information = {};

        const {
          mobile_service_vodafone,
          mobile_service_kyivstar,
          mobile_service_life,
        } = selectedPointManager.service_config;

        if (mobile_service_vodafone) {
          payoutMethod.additional_payout_information.mobile_service_vodafone =
            mobile_service_vodafone;
        }

        if (mobile_service_life) {
          payoutMethod.additional_payout_information.mobile_service_life =
            mobile_service_life;
        }

        if (mobile_service_kyivstar) {
          payoutMethod.additional_payout_information.mobile_service_kyivstar =
            mobile_service_kyivstar;
        }
      }
      if (payoutMethod.serviceIds && payoutMethod.pointId) {
        payoutMethod.isActive = true;
      }
    }
    setPayoutMethods([
      ...defaultMethods.map((pm) => {
        pm.isLoaded = true;
        return pm;
      }),
    ]);
    setLoading(false);
  }, [currency, wallet, selectedPointManager]);

  useEffect(() => {
    const method = find(payoutMethods, (el) => el.slug === selectedSlug);
    if (selectedService && method && method.pointId) {
      method.serviceId = selectedService;
      getServiceCommissions({
        serviceId: selectedService,
        pointId: method.pointId || 0,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            method.commissionsData = response;
          } else {
            method.commissionsData = null;
          }
        })
        .catch((err) => {
          console.error("Error", err);
          method.commissionsData = null;
        });
    }
  }, [selectedSlug, selectedService]);

  return { payoutMethods, loading };
}
