import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PageTransactionsQueryParams,
  useGetPageTransactionsQuery,
} from "api/baseAPI/invoicing";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { PageTransactionsFilters } from "pages/invoicing/transactions/parts/PageTransactionsFilters";
import { PageTransactionsFiltersRow } from "pages/invoicing/transactions/parts/PageTransactionsFiltersRow";
import { useFilterMaker } from "utils/filterMaker";
import { useUrlQuery } from "utils/url";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  CopyCell,
  DataItem,
  StatusPageTransaction,
} from "./AdditionalComponents";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  page_id__in: "",
  page__account__in: "",
  id: "",
  external_id: "",
  external_uuid: "",
  created_at__gte: "",
  created_at__lte: "",
  amount__gte: "",
  amount__lte: "",
  status: "",
  page_id: "",
  description: "",
};

export const PageTransactionsTable = ({ page_id }: { page_id: string }) => {
  const { t } = useTranslation();
  const [openId, setOpenId] = useState<string>("");
  const selectedPointManager = useSelectedPointManager();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<PageTransactionsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_created_at__gte(values.created_at__gte || "");
      querySetters.set_created_at__lte(values.created_at__lte || "");
      querySetters.set_amount__gte(values.amount__gte || "");
      querySetters.set_amount__lte(values.amount__lte || "");
      querySetters.set_status(values.status || "");
      querySetters.set_id(values.id || "");
      querySetters.set_description(values.description || "");
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_status(initParameters.status);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, refetch } = useGetPageTransactionsQuery({
    ...queryParams,
    page_id,
    page__point: `${selectedPointManager?.point_id || ""}`,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  return (
    <Table
      isLoading={isFetching}
      tableCellTitles={[
        "ID",
        t("ID транзакции"),
        t("UUID Транзакции"),
        t("Сумма"),
        t("Время"),
        t("Статус"),
      ]}
      filterBar={
        <FilterBar
          search={search}
          setSearch={handleSetSearch}
          refetch={refetch}
          filters={<PageTransactionsFilters disabledPage filters={filters} />}
        />
      }
      filtersRow={
        <PageTransactionsFiltersRow
          queryParams={queryParams}
          querySetters={querySetters}
        />
      }
      count={data?.count || 0}
      page={Number(page)}
      rowsPerPage={Number(page_size)}
      setPage={handleSetPage}
      setRowsPerPage={handleSetRowsPerPage}
    >
      {data &&
        data.results.map((row) => (
          <React.Fragment key={row.id}>
            <StyledRow>
              <StyledCell>
                <Box flex alignItems="center">
                  <IconCellOpen
                    open={openId === row.id}
                    onClick={() => setOpenId(openId ? "" : row.id)}
                  />
                  <div>{row.id}</div>
                </Box>
              </StyledCell>
              <StyledCell>{row.external_id || ""}</StyledCell>
              <StyledCell>{row.external_uuid || ""}</StyledCell>
              <StyledCell>
                {row.amount ? amountFormat(row.amount) : ""}
              </StyledCell>
              <StyledCell>{dateFormat(row.created_at)}</StyledCell>
              <StyledCell>
                <StatusPageTransaction status={row.status} />
              </StyledCell>
              <StyledCell />
            </StyledRow>
            {openId === row.id && (
              <StyledRow>
                <td colSpan={7}>
                  <Grid container pl={8} pr={1} p={16} hSpace={16} vSpace={16}>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("Полное имя")}
                        content={
                          row?.customer_full_name ? (
                            <CopyCell content={row?.customer_full_name} />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("Номер телефона")}
                        content={
                          row?.customer_phone_number ? (
                            <CopyCell content={row?.customer_phone_number} />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("Почта")}
                        content={
                          row?.customer_email ? (
                            <CopyCell content={row?.customer_email} />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("Комментарий")}
                        content={row.description}
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("ФИО")}
                        content={
                          row?.customer_first_name ? (
                            <CopyCell
                              content={`${row?.customer_first_name} ${row?.customer_last_name} ${row?.customer_patronymic}`}
                            />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                    <Grid sm={3} item>
                      <DataItem
                        text={t("Дата рождения")}
                        content={
                          row?.customer_birth_date ? (
                            <CopyCell content={row?.customer_birth_date} />
                          ) : (
                            ""
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </td>
              </StyledRow>
            )}
          </React.Fragment>
        ))}
    </Table>
  );
};
