import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { BinancePayPayoutForm } from "pages/payout/logic/forms/BinancePayPayoutForm";
import { ReactComponent as BinanceIcon } from "utils/img/binance.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class BinancePayPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "binance_id";

  kind: PayoutKind = PayoutKinds.BINANCE_PAY;

  name: string = "Binance Pay";

  mobileName: string = this.name;

  icon: ReactElement = (<BinanceIcon />);

  filenameExample = "sample-binance-pay";

  jsonExample = [
    {
      Email: "some@example.com",
      Сумма: 23.33,
      Примечание: "note 1",
    },
    {
      Email: "some2@example.com",
      Сумма: 50,
      Примечание: "note 2",
    },
    {
      Email: "some3@example.com",
      Сумма: 5050,
      Примечание: "note 3",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.binance_id_payouts &&
        this.getService(
          this.pointManager?.service_config?.binance_id_payouts
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = () => <></>;

  customerAccountValidationSchema = yup.string().required("Введите email");

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <BinancePayPayoutForm payoutMethod={payoutMethod} />
  );
}
