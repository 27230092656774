import React, { useState } from "react";
import styled from "styled-components";
import { Box } from "UI/Box";
import { StyledLabelInput } from "UI/Form/TextInput";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as Close } from "utils/img/close.svg";
import { hexToRGB } from "utils/mixColors";
import {
  handleChangeNumber,
  numberForInput,
} from "utils/numbers";
import { useNumberFormatter } from "hooks/useNumberFormatter";

type InputTagsProps = {
  placeholder?: string;
  label?: string;
  name?: string;
  type?: "text" | "password" | "number";
  value?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (val: string[]) => void;
  error?: boolean | string;
  helperText?: string | boolean;
  size?: "small" | "default";
  inputProps?: any;
  style?: React.CSSProperties;
  maxIntLength?: number;
};

export const InputTags = (props: InputTagsProps) => {
  const {
    placeholder,
    label,
    name,
    type,
    value,
    disabled,
    readOnly,
    onChange,
    error,
    helperText,
    size,
    inputProps,
    style,
    maxIntLength,
  } = props;
  const [focus, setFocus] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const { accentColor } = getColors();
  const { amountFormat } = useNumberFormatter();

  return (
    <StyledWrapper>
      <StyledFieldset
        disabled={disabled}
        error={!!error}
        bordercolor={accentColor}
        focus={focus}
      >
        <StyledLabelInput empty={!label} size={size}>
          {label || ""}
        </StyledLabelInput>

        <StyledTags small={size === "small"}>
          {[...(value || [])]
            ?.sort((a: string, b: string) => Number(a) - Number(b))
            ?.map((tag, index) => (
              <Box
                p={4}
                mr={4}
                flex
                nowrap
                alignItems="center"
                style={{ background: "#EAEFF7", height: 20 }}
                key={index}
              >
                <Typography
                  variant="subtitle3"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {amountFormat(tag)}
                </Typography>
                <StyledClose
                  onClick={() => {
                    const preferredAmounts = [...(value || [])]?.sort(
                      (a: string, b: string) => Number(a) - Number(b)
                    );
                    if (index !== -1) {
                      preferredAmounts.splice(index, 1);
                    }
                    onChange(preferredAmounts);
                  }}
                />
              </Box>
            ))}
        </StyledTags>
        <StyledTextInput
          type={type || "text"}
          placeholder={value && value?.length < 3 ? placeholder : ""}
          name={name}
          onChange={(e) => {
            if (value && value?.length < 3) {
              setInputValue(
                handleChangeNumber(
                  maxIntLength
                    ? e.target.value
                        .replace(/[^0-9.]/g, "")
                        .slice(
                          0,
                          e.target.value.includes(".")
                            ? maxIntLength + 3
                            : maxIntLength
                        )
                    : e.target.value.replace(/[^0-9.]/g, "")
                )
              );
            }
          }}
          value={numberForInput(inputValue)}
          disabled={disabled}
          error={!!error}
          bordercolor={accentColor}
          length={value?.length}
          small={size === "small"}
          {...inputProps}
          style={style}
          readOnly={!!((value && value?.length > 2) || readOnly)}
          onBlur={() => {
            setFocus(false);
            const newValue = `${(inputValue.split(".")[0] || "").replace(
              " ",
              ""
            )}.${
              Number(inputValue || 0)
                .toFixed(2)
                .split(".")[1]
            }`;
            if (newValue) {
              if (Number(newValue) && !value?.includes(newValue)) {
                onChange([...(value || []), handleChangeNumber(newValue)]);
              }
              setInputValue("");
            }
          }}
          onKeyDown={(e) => {
            setFocus(true);
            const newValue = `${(inputValue.split(".")[0] || "").replace(
              " ",
              ""
            )}.${
              Number(inputValue || 0)
                .toFixed(2)
                .split(".")[1]
            }`;
            if (
              e.key === "Enter" &&
              newValue &&
              Number(newValue) &&
              !value?.includes(newValue)
            ) {
              setInputValue("");
              onChange([...(value || []), newValue]);
            }
          }}
        />
      </StyledFieldset>
      {helperText ? (
        <StyledHelperText error={!!error}>{helperText}</StyledHelperText>
      ) : null}
    </StyledWrapper>
  );
};

const StyledFieldset = styled.fieldset<{
  error?: boolean;
  bordercolor: string;
  focus?: boolean;
}>`
  display: flex;
  border: none;
  border: 1px solid ${(props) => (props.error ? "#D00025" : "#B9BFC8")};
  padding: 0;
  margin-top: -8px;
  padding-left: 5px;
  &:hover {
    border: 1px solid
      ${(props) => (props.error ? "#D00025" : hexToRGB(props.bordercolor, 0.4))};
    legend {
      color: ${(props) => hexToRGB(props.bordercolor, 0.4)};
    }
  }
  ${(props) =>
    props.focus
      ? `
          border: 1px solid
            ${props.error ? "#D00025" : props.bordercolor} !important;
          legend {
            color: ${props.bordercolor} !important;
          }
        `
      : ""};
  &:disabled {
    opacity: 0.8;
    border: 1px solid #c8cfdc;
    legend {
      color: #c8cfdc;
    }
  }
`;

const StyledTags = styled.div<{
  small?: boolean;
}>`
  align-items: center;
  display: flex;
`;
const StyledClose = styled(Close)`
  margin-left: 4px;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

type StyledInputProps = {
  length: number;
  error?: boolean;
  small?: boolean;
  bordercolor: string;
};

const StyledTextInput = styled.input<StyledInputProps>`
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
  ${(props) =>
    props.small
      ? `
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          padding: 14.5px;
        `
      : ""};
  width: 100%;
  padding-top: 6.5px;
  color: #19202e;
  box-sizing: border-box;
  background: transparent;
  &:focus {
    outline: none;
  }
  &:placeholder {
    color: #727272;
  }
`;

const StyledHelperText = styled.div`
  font-weight: 400;
  margin-top: 4px;
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { error: boolean }) =>
    props.error ? "#D00025" : "#727272"};
`;
