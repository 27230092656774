import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty, map, toPairs } from "lodash";
import {
  InvoiceTransactionsQueryParams,
  useGetInvoiceTransactionsQuery,
} from "api/baseAPI/checkoutInvoiceTransactions";
import { usePaymentMethodNames } from "api/baseAPI/pointServices";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import {
  CopyCell,
  DataItem,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { TransactionStatus } from "pages/transactions/parts/TransactionStatus";
import { useFilterMaker } from "utils/filterMaker";
import { useUrlQuery } from "utils/url";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { TransactionsFilters } from "./parts/TransactionsFilters";
import { TransactionsFiltersRow } from "./parts/TransactionsFiltersRow";

const initParameters = {
  offset: "",
  limit: "",
  order_by: "",
  page: "1",
  page_size: "10",
  search: "",
  invoice_uuid: "",
  created_at__gte: "",
  created_at__lte: "",
  payment_method_slug: "",
  invoice__point_id: "",
};

// const DataFields = ({ fields }: { fields: Record<string, string> }) => {
//   if (isEmpty(fields)) return <></>;
//   return map(toPairs(fields), ([key, value], i) => (
//     <Grid sm={3} item key={i}>
//       <DataItem text={key} content={value} />
//     </Grid>
//   ));
// };

export const Transactions = () => {
  const { t } = useTranslation();
  const [openUUID, setOpenUUID] = useState<string>("");

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Транзакции"), ""],
  ];

  const tableCellTitles = [
    "UUID",
    "",
    t("Инвойс"),
    t("ID транзакции"),
    t("Метод"),
    t("Сумма"),
    t("Время"),
    t("Статус"),
  ];
  const selectedPointManager = useSelectedPointManager();
  const paymentMethodNames = usePaymentMethodNames();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<InvoiceTransactionsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const {
    set_page,
    set_page_size,
    set_search,
    set_created_at__gte,
    set_created_at__lte,
    set_payment_method_slug,
    set_invoice_uuid,
  } = querySetters;

  const filters = useFilterMaker(
    {
      ...initParameters,
    },
    queryParams,
    (values) => {
      set_page("1");
      set_created_at__gte(values.created_at__gte || "");
      set_created_at__lte(values.created_at__lte || "");
      set_payment_method_slug(values.payment_method_slug || "");
      set_invoice_uuid(values.invoice_uuid || "");
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, isSuccess, refetch } =
    useGetInvoiceTransactionsQuery({
      ...queryParams,
      limit: page_size || "",
      offset: String((Number(page) - 1) * Number(page_size) || 0),
      order_by: "-created_at",
      invoice__point_id: `${selectedPointManager?.point_id || ""}`,
    });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_search(searchVal);
  };

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              filters={<TransactionsFilters filters={filters} />}
              hideSearch
            />
          }
          filtersRow={
            <TransactionsFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          // count={data?.pagination?.total || 0}
          count={0}
          dataLength={
            isSuccess && data?.results?.length ? data?.results?.length : 0
          }
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {isSuccess && data ? (
            data.results?.map((row, index) => (
              <React.Fragment key={index}>
                <StyledRow>
                  <StyledCell px={4}>
                    {row.uuid && <CopyCell content={row.uuid} />}
                  </StyledCell>
                  <StyledCell px={4}>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openUUID === row.uuid}
                        onClick={() => setOpenUUID(openUUID ? "" : row.uuid)}
                      />
                    </Box>
                  </StyledCell>
                  <StyledCell px={4}>
                    {row.invoice_uuid && (
                      <CopyCell content={row.invoice_uuid} />
                    )}
                  </StyledCell>
                  <StyledCell px={4}>
                    {row.external_id && <CopyCell content={row.external_id} />}
                  </StyledCell>
                  <StyledCell px={4}>
                    {paymentMethodNames?.[row.payment_method_slug] ||
                      row.payment_method_slug}
                  </StyledCell>
                  <StyledCell px={4}>
                    {`${amountFormat(row.amount)} ${row.amount_currency}`}
                  </StyledCell>
                  <StyledCell px={4}>{dateFormat(row.created_at)}</StyledCell>
                  <StyledCell px={4}>
                    <TransactionStatus
                      status={row.status}
                      failure_reason_description=""
                      id={0}
                    />
                  </StyledCell>
                </StyledRow>
                {openUUID === row.uuid && (
                  <StyledRow>
                    <td colSpan={8}>
                      <>
                        {!isEmpty(row.fields) ? (
                          <Grid
                            container
                            pl={8}
                            p={16}
                            pb={1}
                            hSpace={16}
                            vSpace={16}
                          >
                            {map(toPairs(row.fields), ([key, value], i) => (
                              <Grid sm={3} item key={i}>
                                <DataItem text={key} content={value} />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <Grid container pl={8} p={16} hSpace={16} vSpace={16}>
                          <Grid sm={3} item>
                            <DataItem
                              text={t("Описание")}
                              content={row.description}
                            />
                          </Grid>
                          <Grid sm={3} item>
                            <DataItem
                              text={t("Ошибка")}
                              content={row.failure_reason_code}
                            />
                          </Grid>
                        </Grid>
                      </>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};
