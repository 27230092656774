import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useGetAccountTariffsQuery } from "api/baseAPI/accountTariffs";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { RowStatusOnOff } from "components/table/RowStatusOnOff";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { useUrlQuery } from "utils/url";
import { DataItem } from "pages/invoicing/pages/parts/AdditionalComponents";
import { useNumberFormatter } from "hooks/useNumberFormatter";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
};

export const Tariffs = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Управление"), ""],
    [t("Тарифы"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Название"),
    t("Лимит"),
    t("Тип карты"),
    t("Валюта"),
    t("Внеш. комиссия"),
    t("Внутр. комиссия"),
    t("Статус"),
  ];

  const [openId, setOpenId] = useState<number>(0);

  const selectedPointManager = useSelectedPointManager();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } = useUrlQuery<{
    page: string;
    page_size: string;
    search: string;
  }>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetAccountTariffsQuery({
    ...queryParams,
    point_id: selectedPointManager.point?.id,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  return (
    <Layout title={t("Тарифы")} breadCrumbs={breadCrumbs}>
      <StyledWrapper>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data?.results.map((row) => (
            <React.Fragment key={row.id}>
              <StyledRow>
                <StyledCell>
                  <Box flex alignItems="center" nowrap>
                    <IconCellOpen
                      open={openId === row.id}
                      onClick={() => handleOpen(row.id)}
                    />
                    {row.id}
                  </Box>
                </StyledCell>
                <StyledCell>{row.name}</StyledCell>
                <StyledCell>{`${amountFormat(
                  row.service?.min_amount
                )} - ${amountFormat(row.service?.max_amount)}`}</StyledCell>
                <StyledCell>{row.card_types?.join(", ")}</StyledCell>
                <StyledCell>{row.currency}</StyledCell>
                <StyledCell>
                  {`${
                    row.commission?.customer?.fix
                      ? `${row.commission?.customer?.fix} ${
                          row.currency || ""
                        } + `
                      : ""
                  }${row.commission?.customer?.percent || 0} %`}
                </StyledCell>
                <StyledCell>
                  {`${
                    row.commission?.point?.fix
                      ? `${row.commission?.point?.fix} ${row.currency || ""} + `
                      : ""
                  }${row.commission?.point?.percent || 0} %`}
                </StyledCell>
                <StyledCell>
                  <RowStatusOnOff status={!!row.status} />
                </StyledCell>
              </StyledRow>
              {openId === row.id && (
                <StyledRow>
                  <td colSpan={8}>
                    <Grid container p={16} hSpace={12} vSpace={12}>
                      <Grid item sm={12}>
                        <Typography variant="h6">
                          {t("Дополнительная информация")}
                        </Typography>
                      </Grid>
                      <Grid item sm={3}>
                        <DataItem
                          text={t("МСС код")}
                          content={row.mcc_codes?.join(", ")}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <DataItem text={t("Штраф")} content={row.penalty} />
                      </Grid>
                      <Grid item sm={6}>
                        <DataItem text={t("Расчёт")} content={row.settlement} />
                      </Grid>
                      <Grid item sm={6}>
                        <DataItem
                          text={t("Список разрешенных стран")}
                          content={row.geo_allowed?.join(", ")}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <DataItem
                          text={t("Список запрещенных стран")}
                          content={row.geo_prohibited?.join(", ")}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </StyledRow>
              )}
            </React.Fragment>
          ))}
        </Table>
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.div`
  padding: 32px 24px;
`;
