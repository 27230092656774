import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { includes, isArray, isEmpty, reduce, replace } from "lodash";
import { DateTime } from "luxon";
import styled from "styled-components";
import { useCreateReportMutation } from "api/baseAPI/reports";
import {
  Transaction,
  TransactionsQueryParams,
  useCancelTransactionMutation,
  useGetTransactionsQuery,
  useResendTransactionCallBackMutation,
} from "api/baseAPI/transactions";
import {
  useGetUsersMeRetrieverPermissionsQuery,
  useGetUsersMeTransactionPermissionsQuery,
} from "api/baseAPI/user";
import { useLazyGetWalletQuery } from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { ButtonSwitch } from "UI/ButtonSwitch";
import { Grid } from "UI/Grid";
import { ModalInfo } from "pages/accountWalletTransactions/parts/ModalInfo";
import { DataItem } from "pages/invoicing/pages/parts/AdditionalComponents";
import { useFilterMaker } from "utils/filterMaker";
import { getTimezone } from "utils/getTimezone";
import { ReactComponent as ArrowsUndoIcon } from "utils/img/Arrow_Undo_Down_Left.svg";
import { ReactComponent as ArrowUpIcon } from "utils/img/Arrow_Up_Right_SM.svg";
import { ReactComponent as MaestroIcon } from "utils/img/Maestro_logo.svg";
import { ReactComponent as MastercardIcon } from "utils/img/Mastercard-Logo.svg";
import { ReactComponent as VisaIcon } from "utils/img/Visa_Logo.svg";
import { ReactComponent as Download } from "utils/img/download.svg";
import { ReactComponent as MirIcon } from "utils/img/mir-logo.svg";
import { ReactComponent as ProstirIcon } from "utils/img/prostir.svg";
import { ReactComponent as StopCircleIcon } from "utils/img/stop-circle.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedWalletManager } from "hooks/useSelectedEntity";
import { useStyle } from "hooks/useStyle";
import { PartCancelForm } from "./parts/PartCancelForm";
import { PixReceipt } from "./parts/PixReceipt";
import { TransactionRowInfo } from "./parts/TransactionRowInfo";
import { TransactionStatus } from "./parts/TransactionStatus";
import { TransactionsFilters } from "./parts/TransactionsFilters";
import { TransactionsFiltersRow } from "./parts/TransactionsFiltersRow";

const CARD_ICONS: Record<string, React.ReactElement> = {
  visa: <VisaIcon />,
  mc: <MastercardIcon />,
  prostir: <ProstirIcon />,
  maestro: <MaestroIcon />,
  mir: <MirIcon />,
};

const DownloadButtons = ({
  handleCreateReport,
  disabledButton,
  valueSwitch,
  onChangeSwitch,
}: {
  handleCreateReport: () => void;
  disabledButton: boolean;
  valueSwitch: boolean;
  onChangeSwitch: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        item
        flex
        sm={12}
        xl={4.23}
        responsive={{ xl: { ml: 14, mt: 0 }, sm: { ml: 0, mt: 16 } }}
      >
        <Box style={{ width: "calc(50% - 12px)" }}>
          <ButtonDownload
            fullwidth
            onClick={handleCreateReport}
            disabled={disabledButton}
          >
            {t("Загрузить")}
          </ButtonDownload>
        </Box>
        <Box style={{ width: "calc(50% - 4px)" }} ml={14}>
          <ButtonSwitchType
            firstTitle="XLS"
            secondTitle="CSV"
            value={valueSwitch}
            onChange={onChangeSwitch}
          />
        </Box>
      </Grid>
    </>
  );
};

export const Transactions = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Отчетность"), ""],
    [t("Транзакции"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Дата"),
    t("Сервис"),
    t("Аккаунт"),
    t("ПС"),
    t("Сумма"),
    t("Сумма мерчанта"),
    t("Комиссия"),
    t("Возвращено"),
    t("Внешний ID"),
    t("Внешний клиент"),
    t("Статус"),
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openId, setOpenId] = useState<number>(0);
  const [fileType, setFileType] = useState<1 | 2>(1);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const timezone = getTimezone();
  const style = useStyle();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const [getWallet, getWalletMeta] = useLazyGetWalletQuery();
  const [createReport, createReportMeta] = useCreateReportMutation();
  const selectedWallet = useSelectedWalletManager();
  const [cancelTransaction, cancelTransactionMeta] =
    useCancelTransactionMutation();
  const [resendTransactionCallBack, resendTransactionCallBackMeta] =
    useResendTransactionCallBackMutation();

  const getRetrieverPermissionsQuery = useGetUsersMeRetrieverPermissionsQuery();
  const getTransactionPermissionsQuery =
    useGetUsersMeTransactionPermissionsQuery();

  useEffect(() => {
    if (createReportMeta.data?.uuid) {
      setOpenModal(true);
    }
  }, [createReportMeta.data]);

  useEffect(() => {
    if (selectedWallet?.id) {
      getWallet({ id: selectedWallet?.id });
    }
  }, [selectedWallet]);

  const initParameters = {
    page: "1",
    page_size: "10",
    search: "",
    account_wallet: selectedWallet?.id ? String(selectedWallet?.id) : "",
    status__in: "",
    service__type: "",
    created_at__gte: DateTime.local()
      .setZone(timezone)
      .minus({ day: 7 })
      .startOf("day")
      .toISO(),
    created_at__lte: DateTime.local()
      .setZone(timezone)
      .plus({ day: 1 })
      .startOf("day")
      .toISO(),
    part_date__gte: "",
    part_date__lte: "",
    id: "",
    service__in: "",
    description__icontains: "",
    external_transaction_id: "",
    external_customer_id: "",
    parent__isnull: "true",
    card_number: "",
  };

  const { queryParams, querySetters } =
    useUrlQuery<TransactionsQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_id(values.id);
      querySetters.set_service__in(values.service__in);
      querySetters.set_description__icontains(values.description__icontains);
      querySetters.set_external_transaction_id(values.external_transaction_id);
      querySetters.set_external_customer_id(values.external_customer_id);
      querySetters.set_card_number(values.card_number);
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_service__type(initParameters.service__type);
      querySetters.set_account_wallet(initParameters.account_wallet);
      querySetters.set_status__in(initParameters.status__in);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, refetch, error } = useGetTransactionsQuery({
    ...queryParams,
    part_date__gte: queryParams.created_at__gte
      ? DateTime.fromISO(queryParams.created_at__gte).toFormat("yyyy-MM-dd")
      : "",
    part_date__lte: queryParams.created_at__lte
      ? DateTime.fromISO(queryParams.created_at__lte).toFormat("yyyy-MM-dd")
      : "",
  });

  useEffect(() => {
    if (selectedWallet?.id)
      querySetters.set_account_wallet(String(selectedWallet?.id));
  }, [selectedWallet]);

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  useSuccessNotification([
    cancelTransactionMeta,
    resendTransactionCallBackMeta,
  ]);
  useErrorNotification([resendTransactionCallBackMeta]);
  useErrorNotification([cancelTransactionMeta]);

  const handleCreateReport = () => {
    const filtersData = reduce(
      {
        ...queryParams,
        model: "Transaction",
        account_wallet_id: queryParams.account_wallet,
        account_wallet: "",
        status__in: includes(queryParams.status__in, ",")
          ? queryParams.status__in.split(",")
          : queryParams.status__in,
        part_date__gte: queryParams.created_at__gte
          ? DateTime.fromISO(queryParams.created_at__gte).toFormat("yyyy-MM-dd")
          : "",
        part_date__lte: queryParams.created_at__lte
          ? DateTime.fromISO(queryParams.created_at__lte).toFormat("yyyy-MM-dd")
          : "",
      },
      (acc, value, key) => ({
        ...acc,
        ...(value && key !== "page" && key !== "page_size"
          ? {
              ...(includes(key, "__in") &&
              value &&
              !includes(value, ",") &&
              !isArray(value)
                ? {
                    [replace(key, "__in", "")]: value,
                  }
                : {
                    [key]: value,
                  }),
            }
          : {}),
      }),
      {}
    );
    createReport({
      account_id: getWalletMeta?.data?.account?.id || 0,
      export_type: fileType,
      filters: filtersData,
    });
  };

  return (
    <Layout title={t("Транзакции")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              {...(includes(getRetrieverPermissionsQuery.data, "add_report")
                ? {
                    customButton: (
                      <DownloadButtons
                        handleCreateReport={handleCreateReport}
                        disabledButton={createReportMeta.isLoading}
                        valueSwitch={fileType === 1}
                        onChangeSwitch={() =>
                          setFileType(fileType === 1 ? 2 : 1)
                        }
                      />
                    ),
                  }
                : {})}
              filters={<TransactionsFilters filters={filters} error={error} />}
            />
          }
          filtersRow={
            <TransactionsFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          count={0}
          dataLength={data?.results?.length || 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && !isEmpty(data.results)
            ? data.results.map((row) => (
                <React.Fragment key={row.id}>
                  <StyledRow>
                    <StyledCell>
                      <Box flex alignItems="center" nowrap>
                        <IconCellOpen
                          open={openId === row.id}
                          onClick={() => handleOpen(row.id)}
                        />
                        {row.id}
                      </Box>
                    </StyledCell>
                    <StyledCell>{dateFormat(row.created_at)}</StyledCell>
                    <StyledCell>
                      {row.service?.name?.length > 25
                        ? `${row.service.name.substring(0, 25)}...`
                        : row.service?.name}
                    </StyledCell>
                    <StyledCell>{row.card_number}</StyledCell>
                    <StyledCell>
                      {row.card_ps ? (
                        <IconBox>{CARD_ICONS[row.card_ps]}</IconBox>
                      ) : null}
                    </StyledCell>
                    <StyledCell>
                      {row.amount && row.amount !== null ? (
                        <>
                          {getCurrencyIcon(row.amount_currency || "")}
                          {amountFormat(row.amount, 4)}
                        </>
                      ) : (
                        ""
                      )}
                    </StyledCell>
                    <StyledCell>
                      {row.provider_amount && row.provider_amount !== null ? (
                        <>
                          {getCurrencyIcon(row.provider_currency || "")}
                          {amountFormat(row.provider_amount, 4)}
                        </>
                      ) : (
                        ""
                      )}
                    </StyledCell>
                    <StyledCell>
                      {row.total_commission && row.total_commission !== null ? (
                        <>
                          {getCurrencyIcon(row.provider_currency || "")}
                          {amountFormat(row.total_commission, 4)}
                        </>
                      ) : (
                        ""
                      )}
                    </StyledCell>
                    <StyledCell>
                      {row.refunded_amount && row.refunded_amount !== null ? (
                        <>
                          {getCurrencyIcon(row.point_currency || "")}
                          {amountFormat(row.refunded_amount, 4)}
                        </>
                      ) : (
                        ""
                      )}
                    </StyledCell>
                    <StyledCell>{row.external_transaction_id}</StyledCell>
                    <StyledCell>{row.external_customer_id}</StyledCell>
                    <StyledCell>
                      <TransactionStatus
                        status={row.status}
                        failure_reason_description={
                          row.failure_reason_description
                        }
                        id={row.id}
                      />
                    </StyledCell>
                    <StyledCell>
                      <TableRowActions
                        actions={[
                          ...([1, 6, 30, 40].includes(Number(row.status)) &&
                          row.service_type !== 1 &&
                          includes(
                            getTransactionPermissionsQuery.data,
                            "cancel"
                          ) &&
                          DateTime.fromISO(row.created_at)
                            .plus({ days: 31 })
                            .toISO() > DateTime.local().toISO()
                            ? [
                                <MenuItemAction
                                  key={1}
                                  onClick={() =>
                                    cancelTransaction({ id: row.id })
                                  }
                                >
                                  <StopCircleIcon /> &nbsp;
                                  {t("Отменить транзакцию")}
                                </MenuItemAction>,
                                <MenuItemAction
                                  key={2}
                                  onClick={() => setTransaction({ ...row })}
                                >
                                  <ArrowsUndoIcon /> &nbsp;
                                  {t("Частично вернуть")}
                                </MenuItemAction>,
                              ]
                            : []),
                          <MenuItemAction
                            key={3}
                            onClick={() =>
                              resendTransactionCallBack({ id: row.id })
                            }
                          >
                            <ArrowUpIcon /> &nbsp;
                            {`${t("Отправить")} callback`}
                          </MenuItemAction>,
                        ]}
                      />
                    </StyledCell>
                  </StyledRow>
                  {openId === row.id && (
                    <StyledRow>
                      <td colSpan={13}>
                        <Grid container p={16} pl={8} hSpace={16} nowrap>
                          <TransactionRowInfo
                            id={row.id}
                            refundedAmount={Number(row.refunded_amount || 0)}
                          />
                          <Grid
                            item
                            sm={3}
                            flex
                            direction="column"
                            justifyContent="flex-end"
                          >
                            <Box my={16}>
                              <DataItem
                                text={t("Примечания")}
                                content={row.description || ""}
                              />
                            </Box>
                            <Box>
                              <DataItem
                                text={t("Чек")}
                                content={
                                  row.service_type === 1 &&
                                  row.method?.slug === "pix" ? (
                                    <PixReceipt transaction={row} />
                                  ) : style.is_fc && row.uuid ? (
                                    <StyledLink
                                      href={`https://${window.location.host}${
                                        style.receipt_endpoint ||
                                        "/ce/download_receipt/"
                                      }${row.uuid}`}
                                      target="_blank"
                                    >
                                      <Download
                                        style={{ verticalAlign: "middle" }}
                                      />{" "}
                                      {t("Скачать чек")}
                                    </StyledLink>
                                  ) : (
                                    <></>
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </td>
                    </StyledRow>
                  )}
                </React.Fragment>
              ))
            : null}
        </Table>
        {openModal ? (
          <ModalInfo
            onClose={() => setOpenModal(false)}
            title={t("Запрос на выгрузку создан")}
          />
        ) : (
          <></>
        )}
        {transaction ? (
          <PartCancelForm
            onClose={() => setTransaction(null)}
            transaction={transaction}
          />
        ) : (
          <></>
        )}
      </Box>
    </Layout>
  );
};

const ButtonSwitchType = styled(ButtonSwitch)`
  div {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const ButtonDownload = styled(Button)`
  padding: 12px 6px;
`;

const IconBox = styled.span`
  svg {
    width: 40px !important;
    height: 20px !important;
  }
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: none;
`;
