import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import { last, map } from "lodash";
import { useGetReportRequestResponsesQuery } from "api/baseAPI/reports";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Box } from "UI/Box";
import { getColors } from "utils/getColors";
import { ReactComponent as DownloadPackageIcon } from "utils/img/Download_Package.svg";
import { useDateFormatter } from "hooks/useDateFormatter";

export const ReportRequestResponses = ({ id }: { id: number }) => {
  const { t } = useTranslation();

  const tableCellTitles = [
    t("ID"),
    t("Комментарий"),
    t("Дата - время"),
    t("Файлы"),
  ];

  const { pageBackground } = getColors();
  const { dateFormat } = useDateFormatter();

  const { data, isFetching } = useGetReportRequestResponsesQuery({
    page: "1",
    page_size: "25",
    request: id,
  });

  return (
    <Table
      tableCellTitles={tableCellTitles}
      isLoading={isFetching}
      count={data ? data.count : 0}
      page={1}
      rowsPerPage={10}
      setPage={() => {}}
      setRowsPerPage={() => {}}
      disabledPagination
      hideActionColumn
    >
      {data && data.results.length > 0 ? (
        data.results.map((row) => (
          <StyledRow key={row.id} background={pageBackground}>
            <StyledCell>{row.id}</StyledCell>
            <StyledCell>{row.response}</StyledCell>
            <StyledCell>{dateFormat(row.created_at)}</StyledCell>
            <StyledCell>
              {row.files?.length ? (
                <Box flex alignItems="center" justifyContent="center">
                  {map(row.files, (file, i) => (
                    <Box
                      key={i}
                      flex
                      mr={8}
                      alignItems="center"
                      justifyContent="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        try {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          FileSaver.saveAs(
                            file.file,
                            last(file.file.split("/"))?.split("?")?.[0] ||
                              file.name
                          );
                        } catch (e) {
                          console.error("Error", e);
                          toast.error(t("Ошибка"));
                        }
                      }}
                    >
                      {i + 1}&nbsp;
                      <DownloadPackageIcon />
                    </Box>
                  ))}
                </Box>
              ) : (
                ""
              )}
            </StyledCell>
          </StyledRow>
        ))
      ) : (
        <></>
      )}
    </Table>
  );
};
