import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import { isEmpty, reduce } from "lodash";
import { privatApi } from "api/axios";
import {
  TRANSACTION_FRAUD_STATUSES,
  TRANSACTION_FRAUD_TYPES,
  TransactionFraud,
  TransactionFraudQueryParams,
  useGetTransactionsFraudInfoQuery,
} from "api/baseAPI/transactionFraud";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Checkbox } from "UI/Form/Checkbox";
import { Grid } from "UI/Grid";
import { useFilterMaker } from "utils/filterMaker";
import { ReactComponent as Download } from "utils/img/download.svg";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { Chat } from "./parts/Chat";
import { ModalActions } from "./parts/ModalActions";
import { TransactionFraudsFilters } from "./parts/TransactionFraudsFilters";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  transaction: "",
  type: "",
  id: "",
  ps: "",
  status: "",
  transaction__amount__gte: "",
  transaction__amount__lte: "",
  transaction__description__icontains: "",
  transaction__amount_currency: "",
};

export const useTransactionFraudTypes = (): Record<
  keyof typeof TRANSACTION_FRAUD_TYPES,
  string
> => {
  const { t } = useTranslation();
  return {
    "1": t("Входящий фрод"),
    "2": t("Чарджбек"),
    "3": t("Чарджбек без 3DS"),
    "4": t("Пре-арбитрация"),
  };
};

export const useTransactionFraudStatuses = (): Record<
  keyof typeof TRANSACTION_FRAUD_STATUSES,
  string
> => {
  const { t } = useTranslation();
  return {
    "1": t("Новый диспут"),
    "2": t("Ожидает документы"),
    "3": t("Закрытый диспут"),
    "4": t("Подтвержденная транзакция"),
  };
};

export const TransactionFrauds = () => {
  const { t } = useTranslation();
  const GET_TRANSACTION_FRAUD_TYPES = useTransactionFraudTypes();
  const GET_TRANSACTION_FRAUD_STATUSES = useTransactionFraudStatuses();

  const tableCellTitles = [
    t("ID Транзакции"),
    t("Внутренний ID диспута"),
    t("Детали транзакции"),
    t("Сумма транзакции"),
    t("Причина диспута"),
    t("Статус"),
  ];

  const [checkedList, setCheckedList] = useState<TransactionFraud[]>([]);
  const [loading, setLoading] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const selectedPointManager = useSelectedPointManager();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<TransactionFraudQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_ps(values.ps);
      querySetters.set_id(values.id);
      querySetters.set_type(values.type);
      querySetters.set_transaction(values.transaction);
      querySetters.set_status(values.status);
      querySetters.set_transaction__amount__gte(
        values.transaction__amount__gte
      );
      querySetters.set_transaction__amount__lte(
        values.transaction__amount__lte
      );
      querySetters.set_transaction__description__icontains(
        values.transaction__description__icontains
      );
      querySetters.set_transaction__amount_currency(
        values.transaction__amount_currency
      );
    },
    () => {
      set_search(initParameters.search);
    }
  );

  const { data, isFetching, refetch, error } = useGetTransactionsFraudInfoQuery(
    {
      ...queryParams,
      transaction__point: `${selectedPointManager?.point_id || ""}`,
    }
  );

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleToggleChecked = (item: TransactionFraud) => {
    if (checkedList.find((el) => el.id === item.id)) {
      // const index = checkedList.indexOf(pointID);
      // if (index !== -1) {
      //   checkedList.splice(index, 1);
      // }
      // setCheckedList([...checkedList]);
      setCheckedList([]);
    } else {
      setCheckedList([item]);
    }
  };

  const fetchExport = async (type: number) => {
    const filtersData = reduce(
      queryParams,
      (acc, value, key) => ({
        ...acc,
        ...(value && key !== "page" && key !== "page_size"
          ? {
              [key]: value,
            }
          : {}),
      }),
      {}
    );
    return privatApi<void>(
      `/retriever/v0/transaction-fraud-info/export-to-file/`,
      "POST",
      {
        filtersData,
        export_type: type,
      },
      {
        responseType: "blob",
        "Access-Control-Expose-Headers": "Content-Disposition",
      }
    );
  };

  const handleDownload = async (type: number) => {
    setLoading(true);
    try {
      const response = await fetchExport(type);

      if (response.status === 204) {
        return;
      }
      let filename = `transaction_frauds.${type === 1 ? "xls" : "csv"}`;
      try {
        const reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          response?.headers?.["content-disposition"] || ""
        );
        if (reg) {
          filename = reg[1].replace(/['"]/g, "");
        }
      } catch (e) {
        console.error("Error", e);
      }

      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        FileSaver.saveAs(response.data, filename);
      } catch (e) {
        console.error("Error", e);
        toast.error(t("Ошибка"));
      }
    } catch (err) {
      toast.error(t("Ошибка"));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const DownloadButtons = () => (
    <>
      <Grid
        item
        flex
        sm={12}
        xl={4}
        responsive={{ xl: { ml: 16, mt: 0 }, sm: { ml: 0, mt: 16 } }}
      >
        <Box style={{ width: "calc(50% - 8px)" }}>
          <Button
            variant="outlined"
            fullwidth
            onClick={() => handleDownload(2)}
            disabled={loading}
            iconStart={<Download />}
          >
            CSV
          </Button>
        </Box>
        <Box style={{ width: "calc(50% - 8px)" }} ml={16}>
          <Button
            disabled={loading}
            onClick={() => handleDownload(1)}
            fullwidth
            variant="outlined"
            iconStart={<Download />}
          >
            XLS
          </Button>
        </Box>
      </Grid>
    </>
  );

  return (
    <Layout title={t("Чарджбеки")}>
      <Box py={32} px={24} mb={checkedList.length ? 100 : 0}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
              customButton={<DownloadButtons />}
              filters={
                <TransactionFraudsFilters filters={filters} error={error} />
              }
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
          hideActionColumn
        >
          {data && !isEmpty(data.results)
            ? data.results.map((row) => (
                <StyledRow key={row.id}>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <Checkbox
                        value={checkedList.includes(row)}
                        onChange={() => handleToggleChecked(row)}
                      />
                      {row.transaction?.id}
                    </Box>
                  </StyledCell>
                  <StyledCell>{row.id}</StyledCell>
                  <StyledCell>{row.transaction?.description}</StyledCell>
                  <StyledCell>
                    {row.transaction?.amount &&
                    row.transaction?.amount !== null ? (
                      <>
                        {getCurrencyIcon(
                          row.transaction?.amount_currency || ""
                        )}
                        {amountFormat(row.transaction?.amount, 4)}
                      </>
                    ) : (
                      ""
                    )}
                  </StyledCell>
                  <StyledCell>
                    {row.type ? GET_TRANSACTION_FRAUD_TYPES[row.type] : ""}
                  </StyledCell>
                  <StyledCell>
                    {row.type ? GET_TRANSACTION_FRAUD_STATUSES[row.status] : ""}
                  </StyledCell>
                </StyledRow>
              ))
            : null}
        </Table>
      </Box>
      {checkedList.length && !openChat ? (
        <ModalActions
          onCancel={() => {
            setCheckedList([]);
            refetch();
          }}
          onOpenChat={() => setOpenChat(true)}
          checkedList={checkedList}
        />
      ) : (
        <></>
      )}
      {checkedList.length && openChat ? (
        <Chat
          transactionFraud={checkedList[0]}
          onClose={() => {
            setCheckedList([]);
            refetch();
            setOpenChat(false);
          }}
        />
      ) : (
        <></>
      )}
    </Layout>
  );
};
