import { isArray, pick } from "lodash";
import { Services } from "api/baseAPI/pointManagers";

export function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const getQuery = (
  params: any,
  keysUsedWithSearch?: string[],
  onlySearch?: boolean,
  keyInstedOfUsedId?: string
) => {
  const query = new URLSearchParams();
  const filterParams = params?.search
    ? pick(params, [
        "created_at__gte",
        "created_at__lte",
        "search",
        "page",
        "page_size",
        "ordering",
        ...(keysUsedWithSearch || []),
      ])
    : { ...params };
  Object.entries(filterParams).forEach(([key, value]) => {
    if (key === "search" && Number(value) && !onlySearch) {
      query.append(keyInstedOfUsedId || "id", String(value));
    } else if (value) {
      query.append(key, String(value));
    } else {
      query.delete(key);
    }
  });
  return query;
};

export const getFirstService = (services: Services): number =>
  isArray(services) ? services[0] : services;
