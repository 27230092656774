import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { includes } from "lodash";
import styled from "styled-components";
import {
  InvoicesQueryParams,
  KIND_CHECKOUT,
  useGetInvoicesQuery,
} from "api/baseAPI/checkoutInvoiceInvoices";
import { useGetUsersMeInvoicePermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { RowStatusOnOff } from "components/table/RowStatusOnOff";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  CopyCell,
  DataItem,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { useFilterMaker } from "utils/filterMaker";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as ExternalLink } from "utils/img/external-link.svg";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { useUrlQuery } from "utils/url";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { InvoiceStats } from "./parts/InvoiceStats";
import { InvoicesFilters } from "./parts/InvoicesFilters";

const initParameters = {
  offset: "",
  limit: "",
  order_by: "",
  page: "1",
  page_size: "10",
  search: "",
  uuid: "",
  title__ilike: "",
  wallet_id: "",
  point_id: "",
};

export const Invoices = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Инвойсинг"), ""],
    [t("Список инвойсов"), ""],
  ];

  const tableCellTitles = [
    "UUID",
    t("Ссылка"),
    t("Описание"),
    t("Тип"),
    t("Статус"),
  ];

  const navigate = useNavigate();

  const [openUuid, setOpenUuid] = useState("");
  const selectedPointManager = useSelectedPointManager();
  const getPermissions = useGetUsersMeInvoicePermissionsQuery();
  const { dateFormat } = useDateFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<InvoicesQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_uuid(values.uuid);
      querySetters.set_title__ilike(values.title__ilike);
      querySetters.set_wallet_id(values.wallet_id);
    },
    () => set_search(initParameters.search)
  );

  const { data, isFetching, isSuccess, refetch, error } = useGetInvoicesQuery({
    ...queryParams,
    limit: page_size || "",
    offset: String((Number(page) - 1) * Number(page_size) || 0),
    order_by: "-created_at",
    point_id: `${selectedPointManager?.point_id || ""}`,
    page: "",
    page_size: "",
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const handleOpen = (uuid: string) => {
    setOpenUuid(openUuid === uuid ? "" : uuid);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.info(t("Скопировано"));
  };

  const getItemUrl = (uuid: string) => {
    if (includes(getPermissions.data, "view_invoice")) {
      return `/invoice/invoices/${uuid}/`;
    }
    return "#";
  };
  return (
    <Layout title={t("Список инвойсов")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Создать инвойс")}
              {...(includes(getPermissions.data, "add_invoice")
                ? {
                    createPath: "/invoice/invoices/create",
                  }
                : {})}
              refetch={refetch}
              filters={<InvoicesFilters filters={filters} error={error} />}
              hideSearch
            />
          }
          count={isSuccess ? data?.pagination?.total || 0 : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {isSuccess && data && data.results.length > 0 && !error ? (
            data.results.map((row) => (
              <React.Fragment key={row.uuid}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openUuid === row.uuid}
                        onClick={() => handleOpen(row.uuid)}
                      />
                      {row.uuid && <CopyCell content={row.uuid} />}
                    </Box>
                  </StyledCell>
                  <StyledCell>
                    {row.pay_url ? (
                      <Box flex nowrap>
                        <StyledIcon>
                          <a
                            href={row.pay_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ExternalLink />
                          </a>
                        </StyledIcon>
                        <StyledIcon onClick={() => handleCopy(row.pay_url)}>
                          <LinkIcon />
                        </StyledIcon>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </StyledCell>
                  <StyledCell>
                    <StyledLink to={getItemUrl(row.uuid)}>
                      {row.title?.length > 50
                        ? `${row.title.substring(
                            0,
                            20
                          )}...${row.title.substring(row.title.length - 20)}`
                        : row.title}
                    </StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink to={getItemUrl(row.uuid)}>
                      {row.kind === KIND_CHECKOUT && t("Чекаут")}
                    </StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink to={getItemUrl(row.uuid)}>
                      <RowStatusOnOff
                        status={
                          !row.active_till ||
                          new Date(row.active_till) > new Date()
                        }
                      />
                    </StyledLink>
                  </StyledCell>
                  <StyledCell key="actions" align="right">
                    <TableRowActions
                      actions={[
                        ...(includes(getPermissions.data, "change_invoice")
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() =>
                                  navigate(`/invoice/invoices/${row.uuid}/edit`)
                                }
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
                {openUuid === row.uuid && (
                  <StyledRow>
                    <td colSpan={7}>
                      <Grid container pl={8} p={16} hSpace={16}>
                        <Grid sm={4} item>
                          <Grid container hSpace={16} vSpace={16}>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Создано")}
                                content={dateFormat(row.created_at)}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Срок действия")}
                                content={
                                  <Typography
                                    variant="inputText"
                                    style={{
                                      color:
                                        row?.active_till &&
                                        new Date(row?.active_till) < new Date()
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {row?.active_till
                                      ? dateFormat(row.active_till)
                                      : ""}
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={4}>
                          <InvoiceStats invoice={row} />
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`;
const StyledIcon = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  svg path {
    fill: #838383 !important;
  }
`;
