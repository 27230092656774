import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import styled from "styled-components";
import { useGetPageStatsByUuidQuery } from "api/baseAPI/invoicing";
import { Box } from "UI/Box";
import { Typography } from "UI/Typography";
import { getCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";

const COLORS = [
  "#303F9F",
  "rgba(237, 108, 2, 0.5)",
  "#E31B0C",
  "rgba(0, 0, 0, 0.14)",
];

export const PageStats = ({
  pageId,
  currency,
}: {
  pageId: string;
  currency: string;
}) => {
  const { t } = useTranslation();

  const getPageStats = useGetPageStatsByUuidQuery({ uuid: pageId || "" });
  const { amountFormat } = useNumberFormatter();

  const getOptions = useMemo(() => {
    if (!isEmpty(getPageStats.data)) {
      const values = getPageStats?.data;
      return [
        { name: t("Успешные"), count: values?.success_count || 0 },
        { name: t("В обработке"), count: values?.pending_count || 0 },
        { name: t("Неуспешная"), count: values?.fail_count || 0 },
        ...(!values?.success_count &&
        !values?.pending_count &&
        !values?.fail_count
          ? [{ name: "", count: 1 }]
          : []),
      ];
    }
    return [];
  }, [getPageStats.data]);

  const getPercent = useMemo(() => {
    if (!isEmpty(getPageStats.data)) {
      const values = getPageStats?.data;
      const amount = values
        ? values.success_count + values.pending_count + values.fail_count
        : 0;
      return [
        (amount ? ((values?.success_count || 0) / amount) * 100 : 0).toFixed(1),
        (amount ? ((values?.pending_count || 0) / amount) * 100 : 0).toFixed(1),
        (amount ? ((values?.fail_count || 0) / amount) * 100 : 0).toFixed(1),
      ];
    }
    return [];
  }, [getPageStats]);

  return (
    <Box
      p={16}
      style={{
        border: "1px solid #c8cfdc",
        height: "auto",
      }}
    >
      {!getPageStats.isLoading ? (
        <>
          <Typography mb={12} variant="h6">
            {t("Статистика транзакций")}
          </Typography>
          <Typography pb={6} variant="subtitle3">
            {t("Общая сумма успешных платежей")}
          </Typography>
          <Typography variant="inputText" style={{ display: "flex" }}>
            <>
              {getCurrencyIcon(currency, "0")}
              {amountFormat(getPageStats?.data?.success_amount || 0)}
            </>
          </Typography>
          <Box flex alignItems="center" style={{ flexWrap: "wrap" }}>
            <Box mr={30} my={10} style={{ width: 210 }}>
              <Box flex justifyContent="space-between" mb={16}>
                <Box flex>
                  <StyledPoint color="#399818" />
                  <Typography variant="subtitle3">{t("Успешные")}</Typography>
                </Box>
                <Typography variant="subtitle3">{`${
                  getPercent[0] || 0
                }%`}</Typography>
              </Box>
              <Box flex justifyContent="space-between" mb={16}>
                <Box flex>
                  <StyledPoint color="#F29100" />
                  <Typography variant="subtitle3">
                    {t("В обработке")}
                  </Typography>
                </Box>
                <Typography variant="subtitle3">{`${
                  getPercent[1] || 0
                }%`}</Typography>
              </Box>
              <Box flex justifyContent="space-between">
                <Box flex>
                  <StyledPoint color="#DC0C0C" />
                  <Typography variant="subtitle3">{t("Неуспешные")}</Typography>
                </Box>
                <Typography variant="subtitle3">{`${
                  getPercent[2] || 0
                }%`}</Typography>
              </Box>
            </Box>
            <PieChart width={145} height={145}>
              <Pie
                data={getOptions}
                innerRadius={45}
                outerRadius={68}
                dataKey="count"
                nameKey="name"
              >
                {getOptions?.map((entry, i) => (
                  <Cell key={`cell-${i}`} fill={COLORS[i]} />
                ))}
              </Pie>
              {getOptions.length < 4 && <Tooltip />}
            </PieChart>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};
const StyledPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background: ${(props) => props.color};
`;
