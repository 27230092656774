import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { includes } from "lodash";
import styled from "styled-components";
import {
  PagesQueryParams,
  useGetPagesQuery,
  useSetStatusPageMutation,
} from "api/baseAPI/invoicing";
import { useGetUsersMeInvoicingPermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { RowStatusOnOff } from "components/table/RowStatusOnOff";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { useFilterMaker } from "utils/filterMaker";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as ExternalLink } from "utils/img/external-link.svg";
import { ReactComponent as LinkIcon } from "utils/img/link.svg";
import { ReactComponent as SwichtRight } from "utils/img/swicht-right.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { DataItem, ParamOnOff } from "./parts/AdditionalComponents";
import { PageStats } from "./parts/PageStats";
import { PagesFilters } from "./parts/PagesFilters";
import { PagesFiltersRow } from "./parts/PagesFiltersRow";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  status: "",
  created_at__lte: "",
  created_at__gte: "",
  title__icontains: "",
  uuid__icontains: "",
  wallet: "",
  service: "",
  success_amount__gte: "",
  success_amount__lte: "",
};

export const Pages = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Список страниц"), ""],
  ];

  const tableCellTitles = [
    t("ID Ссылки"),
    t("Название ссылки"),
    t("Услуга"),
    t("Кошелек"),
    t("Сумма платежей"),
    t("Статус"),
  ];

  const navigate = useNavigate();

  const [openId, setOpenId] = useState<number>(0);
  const [openUuid, setOpenUuid] = useState("");
  const selectedPointManager = useSelectedPointManager();
  const getInvoicingPermissions = useGetUsersMeInvoicingPermissionsQuery();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<PagesQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_title__icontains(values.title__icontains);
      querySetters.set_uuid__icontains(values.uuid__icontains);
      querySetters.set_wallet(values.wallet);
      querySetters.set_service(values.service);
      querySetters.set_success_amount__gte(values.success_amount__gte);
      querySetters.set_success_amount__lte(values.success_amount__lte);
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_status(initParameters.status);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, refetch, error } = useGetPagesQuery({
    ...queryParams,
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const [setStatusPage, setStatusPageMeta] = useSetStatusPageMutation();

  const handleChangeStatusPage = (id: string, status: number) => {
    setStatusPage({ id, status });
  };

  useSuccessNotification([setStatusPageMeta]);
  useErrorNotification([setStatusPageMeta]);

  const handleOpen = (id: number, uuid: string) => {
    setOpenUuid(openUuid === uuid ? "" : uuid);
    setOpenId(openId === id ? 0 : id);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.info(t("Скопировано"));
  };

  const getItemUrl = (uuid: string) => {
    if (includes(getInvoicingPermissions.data, "view_page")) {
      return `/invoicing/pages/${uuid}/`;
    }
    return "#";
  };
  return (
    <Layout title={t("Список страниц")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Создать платежную страницу")}
              {...(includes(getInvoicingPermissions.data, "add_page")
                ? {
                    createPath: "/invoicing/pages/create",
                  }
                : {})}
              refetch={refetch}
              filters={<PagesFilters filters={filters} error={error} />}
            />
          }
          filtersRow={
            <PagesFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          count={data && !error ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && data.results.length > 0 && !error ? (
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() => handleOpen(row.id, row.uuid)}
                      />
                      {row.pay_url ? (
                        <>
                          <StyledIcon>
                            <a
                              href={row.pay_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ExternalLink />
                            </a>
                          </StyledIcon>
                          <StyledIcon onClick={() => handleCopy(row.pay_url)}>
                            <LinkIcon />
                          </StyledIcon>
                        </>
                      ) : (
                        <></>
                      )}
                      <StyledLink to={getItemUrl(row.uuid)}>
                        {row.id}
                      </StyledLink>
                    </Box>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink to={getItemUrl(row.uuid)}>
                      {row.title}
                    </StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink
                      to={getItemUrl(row.uuid)}
                    >{`${row.service?.id} | ${row.service?.name}`}</StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink
                      to={getItemUrl(row.uuid)}
                    >{`${row.wallet?.id} | ${row.wallet?.name}`}</StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink
                      to={getItemUrl(row.uuid)}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {getCurrencyIcon(row.amount_currency, "0")}
                      {amountFormat(row.success_amount)}
                    </StyledLink>
                  </StyledCell>
                  <StyledCell>
                    <StyledLink to={getItemUrl(row.uuid)}>
                      <RowStatusOnOff status={!!row.status} />
                    </StyledLink>
                  </StyledCell>
                  <StyledCell key="actions" align="right">
                    <TableRowActions
                      actions={[
                        ...(includes(
                          getInvoicingPermissions.data,
                          "change_page"
                        )
                          ? [
                              ...(row.is_reusable ||
                              (!row.is_reusable && !row.success_count)
                                ? [
                                    <MenuItemAction
                                      key={2}
                                      onClick={() =>
                                        handleChangeStatusPage(
                                          row.uuid,
                                          row.status === 1 ? 0 : 1
                                        )
                                      }
                                    >
                                      <SwichtRight />
                                      &nbsp;
                                      {row.status === 1
                                        ? t("Выключить")
                                        : t("Включить")}
                                    </MenuItemAction>,
                                  ]
                                : []),
                              <MenuItemAction
                                key={3}
                                onClick={() =>
                                  navigate(`/invoicing/pages/${row.uuid}/edit`)
                                }
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow>
                    <td colSpan={7}>
                      <Grid container pl={8} p={16} hSpace={16}>
                        <Grid sm={4} item>
                          <Grid container hSpace={16} vSpace={16}>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Создано")}
                                content={dateFormat(row.created_at)}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Срок действия")}
                                content={
                                  <Typography
                                    variant="inputText"
                                    style={{
                                      color:
                                        row?.active_till &&
                                        new Date(row?.active_till) < new Date()
                                          ? "red"
                                          : "black",
                                    }}
                                  >
                                    {row?.active_till
                                      ? dateFormat(row.active_till)
                                      : ""}
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Имя плательщика")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "name"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Email плательщика")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "email"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Номер телефона")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "phone"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Комментарий")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "comment"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("ФИО")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "first_name"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Дата рождения")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "birth_date"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Страна")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "country"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Город")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "city"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Адрес")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "address"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DataItem
                                text={t("Почтовый индекс")}
                                content={
                                  <ParamOnOff
                                    includes={includes(
                                      row.additional_fields,
                                      "zip_code"
                                    )}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={4}>
                          <PageStats
                            pageId={openUuid}
                            currency={row?.amount_currency || ""}
                          />
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`;
const StyledIcon = styled.div`
  padding: 4px;
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  svg path {
    fill: #838383 !important;
  }
`;
