import { useMemo } from "react";
import { useCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";

export const useInfoMinAmount = (
  minAmount: number,
  currency: string | null
) => {
  const currencyIcon = useCurrencyIcon(currency || "UAH");
  const { amountFormat } = useNumberFormatter();

  return useMemo(() => {
    if (minAmount) {
      return (
        <>
          min:
          {currencyIcon}
          {amountFormat(minAmount)}
          &nbsp;
          {currency}
        </>
      );
    }
    return "";
  }, [minAmount, currency]);
};
