import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { includes, isEmpty, map } from "lodash";
import {
  PageTemplate,
  PageTemplatesQueryParams,
  useCreatePageMutation,
  useDeletePageTemplateMutation,
  useGetPageTemplatesQuery,
} from "api/baseAPI/invoicing";
import { useGetUsersMeInvoicingPermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { IconCellOpen } from "components/table/IconCellOpen";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  IconColor,
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  DataItem,
  ParamOnOff,
} from "pages/invoicing/pages/parts/AdditionalComponents";
import { useFilterMaker } from "utils/filterMaker";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as PlusIcon } from "utils/img/plus-outlined.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { getCurrencyIcon } from "utils/useStyle";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { PageTemplatesFilters } from "./parts/PageTemplatesFilters";
import { PageTemplatesFiltersRow } from "./parts/PageTemplatesFiltersRow";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  created_at__lte: "",
  created_at__gte: "",
  title__icontains: "",
  id: "",
  wallet: "",
  service: "",
  fixed_amount__gte: "",
  fixed_amount__lte: "",
};

export const Templates = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Шаблоны"), ""],
  ];

  const tableCellTitles = [
    t("ID Ссылки"),
    t("Название"),
    t("Услуга"),
    t("Кошелек"),
    t("Валюта"),
    t("Создано"),
  ];

  const navigate = useNavigate();

  const [openId, setOpenId] = useState<number>(0);
  const selectedPointManager = useSelectedPointManager();
  const getInvoicingPermissions = useGetUsersMeInvoicingPermissionsQuery();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } =
    useUrlQuery<PageTemplatesQueryParams>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const filters = useFilterMaker(
    initParameters,
    queryParams,
    (values) => {
      set_page("1");
      querySetters.set_title__icontains(values.title__icontains);
      querySetters.set_id(values.id);
      querySetters.set_service(values.service);
      querySetters.set_fixed_amount__gte(values.fixed_amount__gte);
      querySetters.set_fixed_amount__lte(values.fixed_amount__lte);
    },
    () => {
      set_search(initParameters.search);
      querySetters.set_wallet(initParameters.wallet);
      querySetters.set_created_at__lte(initParameters.created_at__lte);
      querySetters.set_created_at__gte(initParameters.created_at__gte);
    }
  );

  const { data, isFetching, refetch } = useGetPageTemplatesQuery({
    ...queryParams,
    point: `${selectedPointManager?.point_id || ""}`,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };
  const [createPaymentPage, createPaymentPageMeta] = useCreatePageMutation();

  const handleOpen = (id: number) => {
    setOpenId(openId === id ? 0 : id);
  };

  const [deletePageTemplate, deletePageTemplateMeta] =
    useDeletePageTemplateMutation();

  const handleDeletePageTemplate = (id: string) => {
    deletePageTemplate({ id });
  };

  const handleCreatePaymentPage = (row: PageTemplate) => {
    createPaymentPage({
      title: row.title,
      amount_currency: row.amount_currency,
      locale: row.locale,
      available_locales: row.available_locales,
      point: String(selectedPointManager.point?.id),
      fixed_amount: row.fixed_amount || null,
      service: row.service?.id ? String(row.service?.id) : "",
      bp_service: row.bp_service?.id ? String(row.bp_service?.id) : null,
      min_amount: row.min_amount || null,
      wallet: row.wallet?.id ? String(row.wallet?.id) : "",
      is_reusable: row.is_reusable,
      additional_fields: row.additional_fields,
      available_payment_methods: row.available_payment_methods,
      preferred_amounts: row.preferred_amounts,
      customization: row.customization?.id
        ? String(row.customization?.id)
        : null,
      status: row.status ? 1 : 0,
      prefilled_fields: row.prefilled_fields,
      active_till: null,
      description_template: row.description_template || "",
      preferred_amounts_logic: row?.preferred_amounts_logic || 1,
      kind: row?.kind || 1,
    });
  };

  useEffect(() => {
    if (createPaymentPageMeta.isSuccess && createPaymentPageMeta?.data?.uuid) {
      navigate(`/invoicing/pages/${createPaymentPageMeta?.data?.uuid}`);
    }
  }, [createPaymentPageMeta.isSuccess, createPaymentPageMeta.data]);

  useSuccessNotification([deletePageTemplateMeta]);
  useErrorNotification([deletePageTemplateMeta, createPaymentPageMeta]);

  return (
    <Layout title={t("Шаблоны")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              createTitle={t("Создать шаблон")}
              {...(includes(getInvoicingPermissions.data, "add_pagetemplate")
                ? {
                    createPath: "/invoicing/page-templates/create",
                  }
                : {})}
              refetch={refetch}
              filters={<PageTemplatesFilters filters={filters} />}
            />
          }
          filtersRow={
            <PageTemplatesFiltersRow
              queryParams={queryParams}
              querySetters={querySetters}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && data.results.length > 0 ? (
            data.results.map((row) => (
              <React.Fragment key={row.id}>
                <StyledRow>
                  <StyledCell>
                    <Box flex alignItems="center" nowrap>
                      <IconCellOpen
                        open={openId === row.id}
                        onClick={() => handleOpen(row.id)}
                      />
                      {row.id}
                    </Box>
                  </StyledCell>
                  <StyledCell>{row.title}</StyledCell>
                  <StyledCell>
                    {`${row.service?.id} | ${row.service?.name}`}
                  </StyledCell>
                  <StyledCell>{`${row.wallet?.id} | ${row.wallet?.name}`}</StyledCell>
                  <StyledCell>{row.amount_currency}</StyledCell>
                  <StyledCell>{dateFormat(row.created_at)}</StyledCell>
                  <StyledCell key="actions" align="right">
                    <TableRowActions
                      actions={[
                        ...(includes(getInvoicingPermissions.data, "add_page")
                          ? [
                              <MenuItemAction
                                key={1}
                                onClick={() => handleCreatePaymentPage(row)}
                              >
                                <IconColor color="#838383">
                                  <PlusIcon />
                                </IconColor>
                                &nbsp;{t("Создать страницу")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getInvoicingPermissions.data,
                          "change_pagetemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={2}
                                onClick={() =>
                                  navigate(
                                    `/invoicing/page-templates/${row.uuid}/edit`
                                  )
                                }
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getInvoicingPermissions.data,
                          "delete_pagetemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() =>
                                  handleDeletePageTemplate(row.uuid)
                                }
                              >
                                <DeleteIcon /> &nbsp;{t("Удалить")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
                {openId === row.id && (
                  <StyledRow>
                    <td colSpan={7}>
                      <Grid container p={16} hSpace={16} vSpace={16}>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Сумма")}
                            content={
                              row.fixed_amount
                                ? amountFormat(row.fixed_amount)
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Минимальная сумма")}
                            content={
                              row.min_amount
                                ? amountFormat(row.min_amount)
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Обновлено")}
                            content={dateFormat(row.updated_at)}
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Предлагаемые суммы")}
                            content={
                              !isEmpty(row.preferred_amounts) ? (
                                <Box flex alignItems="center">
                                  {map(row.preferred_amounts, (item) => (
                                    <Box
                                      p={4}
                                      mr={2}
                                      mb={-6}
                                      style={{ background: "#EAEFF7" }}
                                      key={item}
                                    >
                                      <Typography
                                        variant="subtitle3"
                                        style={{ display: "flex" }}
                                      >
                                        <>
                                          {getCurrencyIcon(
                                            row.amount_currency || ""
                                          )}
                                          {amountFormat(item)}
                                        </>
                                      </Typography>
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <></>
                              )
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Имя плательщика")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "name"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Email плательщика")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "email"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Номер телефона")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "phone"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Комментарий")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "comment"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("ФИО")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "first_name"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={3}>
                          <DataItem
                            text={t("Дата рождения")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "birth_date"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <DataItem
                            text={t("Страна")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "country"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <DataItem
                            text={t("Город")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "city"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <DataItem
                            text={t("Адрес")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "address"
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <DataItem
                            text={t("Почтовый индекс")}
                            content={
                              <ParamOnOff
                                includes={includes(
                                  row.additional_fields,
                                  "zip_code"
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </td>
                  </StyledRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Table>
      </Box>
    </Layout>
  );
};
