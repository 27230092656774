import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { CardInput } from "pages/payout/logic/forms/components/inputs/CardInput";
import { ReactComponent as CardIcon } from "utils/img/card.svg";
import { luhnCreditCardValidation } from "utils/validateByLuhn";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class CardPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "card";

  kind: PayoutKind = PayoutKinds.CARD;

  name: string = "По банковской карте";

  mobileName: string = "По банк. карте";

  icon: ReactElement = (<CardIcon />);

  filenameExample = "sample-card";

  jsonExample = [
    {
      "Номер карты": 4000000000000010,
      Сумма: 23.33,
      Примечание: "Privatbank 1",
    },
    {
      "Номер карты": 4012888888881881,
      Сумма: 50,
      Примечание: "Privatbank 2",
    },
    {
      "Номер карты": 4012888888881881,
      Сумма: 5050,
      Примечание: "Privatbank 3",
    },
  ];

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <CardInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .test(
      "isValidByLuhn",
      "Номер карты невалидный",
      (value: string | undefined): boolean =>
        Boolean(value && luhnCreditCardValidation(value))
    )
    .required("Введите номер карты");

  get serviceIds() {
    return this.pointManager?.service_config?.card_payouts || null;
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return "card_payouts";
  }
}
