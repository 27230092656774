import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { EmailPayInput } from "pages/payout/logic/forms/components/inputs/EmailPayInput";
import { ReactComponent as BinanceIcon } from "utils/img/binance.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class BinancePayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "binance";

  kind: PayoutKind = PayoutKinds.BINANCE;

  name: string = "Binance";

  mobileName: string = this.name;

  icon: ReactElement = (<BinanceIcon />);

  filenameExample = "sample-binance-pay";

  jsonExample = [
    {
      Получатель: "123456789",
      "Тип получателя": "BINANCE_ID",
      Сумма: 23.33,
      Примечание: "note 1",
    },
    {
      Получатель: "some@example.com",
      "Тип получателя": "EMAIL",
      Сумма: 50,
      Примечание: "note 2",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.binance_create_code &&
        this.getService(
          this.pointManager?.service_config?.binance_create_code
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <EmailPayInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .email("Неправильный формат email")
    .required("Введите email");
}
