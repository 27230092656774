import { getQuery } from "utils/helpers";
import { baseSplitApi } from "./index";

export const REGISTRY_STATUS = {
  0: "Создан",
  1: "В обработке",
  2: "Обработан",
  3: "Ошибка",
};

export type PayoutRegistriesQueryParams = {
  page: string;
  page_size: string;
  point_id: string;
};

export type PayoutsQueryParams = {
  page: string;
  page_size: string;
  search: string;
  registry__account_wallet_id: string;
  registry: string;
  created_at__gte: string;
  created_at__lte: string;
  amount__gte: string;
  amount__lte: string;
  customer_account__icontains: string;
  registry__point_id: string;
};

type PayoutsItem = {
  amount: string;
  amount_currency: string;
  created_at: string;
  customer_account: string;
  description: string;
  error_code: number | null;
  id: number;
  is_failed: boolean;
  is_processed: boolean;
  registry: number;
  transaction_id: number;
  transaction_status: number;
  updated_at: string;
  uuid: string;
};


export const PayoutKinds = {
  CARD: 1,
  PHONE: 2,
  SETTLEPAY: 3,
  BINANCE: 4,
  EXMO: 5,
  WHITE_BIT: 6,
  PIX: 7,
  KUNA_CODE: 8,
  UPI: 9,
  BINANCE_PAY: 10,
  SPEI: 11,
  IBAN: 12,
} as const;
export type PayoutKind = ValueOf<typeof PayoutKinds>;

export type CreatePayoutQueryParams = {
  account_wallet_id: number;
  point_id: number;
  kind: PayoutKind;
  service_id: number;
  payouts: {
    customer_account: string;
    amount: number;
    amount_currency: string;
    description: string;
    fields?: {
      first_name?: string;
      last_name?: string;
      receiver_type?: string;
      tax_id?: string;
      tax_id_country?: string;
      IFSC?: string;
      AccHolderName?: string;
      BeneficiaryMobile?: string;
      BeneficiaryEmail?: string;
      BeneficiaryAddress?: string;
    };
  }[];
  delay?: number;
  additional_payout_information?: {
    mobile_service_kyivstar?: number;
    mobile_service_vodafone?: number;
    mobile_service_life?: number;
  };
  fields?: Record<string, string>;
};

export const PayoutFieldType = {
  STRING: 0,
  INTEGER: 1,
  FLOAT: 2,
  BOOLEAN: 3,
} as const;

type ValueOf<T> = T[keyof T];
type PayoutFieldTypeValues = ValueOf<typeof PayoutFieldType>;

export interface AdditionalPayoutsField {
  name: string;
  gate_key: string;
  regex: string;
  placeholder: string;
  type: PayoutFieldTypeValues;
  translations: Record<string, string>;
  listing_order: number;
}

export const payoutsApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createPayoutRegistry: builder.mutation<
      { id: number },
      CreatePayoutQueryParams
    >({
      query: (data) => ({
        url: `/payouts/payout-registries/`,
        method: "POST",
        body: data,
      }),
    }),
    getPayoutRegistries: builder.query<
      {
        results: {
          account_wallet: { id: number; name: string };
          amount_of_failed_payouts: string;
          amount_of_payouts: string;
          amount_of_successful_payouts: string;
          created_at: string;
          id: number;
          kind: number;
          number_of_failed_payouts: number;
          number_of_payouts: number;
          number_of_successful_payouts: number;
          point: { id: number; name: string };
          service: { id: number; name: string };
          status: 0 | 1 | 2 | 3;
          updated_at: string;
          user: { id: number; username: string };
          user_ip_address: string;
        }[];
        count: number;
      },
      PayoutRegistriesQueryParams
    >({
      query: (arg) => {
        const query = new URLSearchParams({ ...arg });
        return {
          url: `/payouts/payout-registries/?${query.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["PayoutRegistries"],
    }),
    saveAsTemplate: builder.mutation<
      { id: number },
      { id: number; point_manager_id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/payouts/payout-registries/${id}/save-as-template/`,
        method: "POST",
        body: data,
      }),
    }),
    registryRetryFailed: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payout-registries/${id}/retry-failed/`,
        method: "POST",
      }),
      invalidatesTags: ["PayoutRegistries"],
    }),
    registrySyncStatus: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payout-registries/${id}/sync/`,
        method: "POST",
      }),
      invalidatesTags: ["PayoutRegistries"],
    }),
    postRegistriesValidate: builder.mutation<
      Record<
        string,
        {
          customer_account: string;
          is_failed: boolean;
          error_code: number | null;
        }
      >,
      {
        account_wallet_id: number;
        point_id: number;
        kind: PayoutKind;
        service_id: number;
        payouts: {
          customer_account: string;
          amount: number | string;
          amount_currency: string;
          description?: string;
          u_validation_key: string;
          fields: {
            receiver_type: string;
          };
        }[];
      }
    >({
      query: (data) => ({
        url: `/payouts/payout-registries/validate/`,
        method: "POST",
        body: data,
      }),
    }),
    getPayouts: builder.query<
      {
        results: PayoutsItem[];
        count: number;
      },
      PayoutsQueryParams
    >({
      query: (arg) => {
        const query = getQuery(arg, [], arg.search?.length > 12);
        return {
          url: `/payouts/payouts/?${query.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Payouts"],
    }),
    getPayout: builder.query<PayoutsItem, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payouts/${id}/`,
        method: "GET",
      }),
    }),
    syncStatus: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/payouts/payouts/${id}/sync/`,
        method: "POST",
      }),
      invalidatesTags: ["Payouts"],
    }),
    getPayoutsAdditionalFields: builder.query<
      AdditionalPayoutsField[],
      {
        id: number;
        config_key: string;
      }
    >({
      query: ({ id, config_key }) => ({
        url: `/services/${id}/get-payout-fields/${config_key}/`,
      }),
    }),
  }),
});

export const {
  useCreatePayoutRegistryMutation,
  useGetPayoutRegistriesQuery,
  useRegistryRetryFailedMutation,
  useRegistrySyncStatusMutation,
  useGetPayoutsQuery,
  useLazyGetPayoutQuery,
  useSyncStatusMutation,
  useSaveAsTemplateMutation,
  usePostRegistriesValidateMutation,
  useGetPayoutsAdditionalFieldsQuery,
  useLazyGetPayoutsAdditionalFieldsQuery,
} = payoutsApi;
