import { ReactChild, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RESPONSIVE_SIZES } from "utils/tools";
import { TablePagination } from "./TablePagination";

type TableProps = {
  children: ReactChild | ReactChild[] | undefined | null;
  tableCellTitles: string[];
  count: number;
  rowsPerPage: number;
  page: number;
  setPage?: (page: number) => void;
  setRowsPerPage?: (rows: number) => void;
  filterBar?: ReactElement;
  filtersRow?: ReactElement;
  isLoading: boolean;
  dataLength?: number;
  disabledPagination?: boolean;
  hideActionColumn?: boolean;
  disabledSelectedRow?: boolean;
  maxHeight?: number;
};

export const Table = (props: TableProps) => {
  const {
    children,
    tableCellTitles,
    count,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    filterBar,
    filtersRow,
    dataLength,
    isLoading,
    disabledPagination,
    hideActionColumn,
    disabledSelectedRow,
    maxHeight,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {filterBar}
      {filtersRow}

      <StyledTableWrapper $maxHeight={maxHeight}>
        <StyledTable cellPadding={0} cellSpacing={0}>
          <thead>
            <StyledRow header>
              {tableCellTitles.map((title) => (
                <StyledTh key={title}>
                  <div className="header-column">{title}</div>
                </StyledTh>
              ))}
              {!hideActionColumn && <StyledTh />}
            </StyledRow>
          </thead>
          <tbody>
            {isLoading ? (
              Array.from(Array(rowsPerPage).keys()).map((row) => (
                <StyledRow key={row}>
                  {tableCellTitles.map((col) => (
                    <StyledCell key={col}>
                      <StyledSkeleton />
                    </StyledCell>
                  ))}
                </StyledRow>
              ))
            ) : count > 0 || dataLength ? (
              children
            ) : (
              <StyledRow key="empty">
                <StyledTh key="alert" colSpan={tableCellTitles.length + 1}>
                  <div>{t("Нет данных для отображения")}</div>
                </StyledTh>
              </StyledRow>
            )}
          </tbody>
        </StyledTable>
      </StyledTableWrapper>
      {!disabledPagination && (
        <TablePagination
          count={count}
          dataLength={dataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          disabledSelectedRow={disabledSelectedRow || false}
          setPage={(newPage: number) => setPage && setPage(newPage)}
          setRowsPerPage={(rows: number) =>
            setRowsPerPage && setRowsPerPage(rows)
          }
        />
      )}
    </>
  );
};

const StyledTableWrapper = styled.div<{ $maxHeight?: number }>`
  overflow-x: auto;
  min-width: 100%;
  overflow-y: auto;
  display: flex;
  ${(props) => (props.$maxHeight ? `max-height: ${props.$maxHeight}px` : "")}
`;
const StyledTable = styled.table`
  min-width: 100%;
  // position: relative;
  width: auto;
  border-spacing: 0 4px;
`;

export const StyledRow = styled.tr`
  height: 40px;
  background: ${(props: { header?: boolean; background?: string }) =>
    !props.header ? props.background || "#FFFFFF" : "transparent"};
  &:hover {
    background: ${(props) =>
      !props.header ? props.background || "#fafafa" : "transparent"};
  }
  & > td:first-child,
  & > th:first-child {
    text-align: left;
    padding-left: 8px;
  }
`;
const StyledTh = styled.th`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #19202e;
  text-align: center;
  white-space: nowrap;
  padding: 0 2px;
`;
export const StyledCell = styled.td<{ px?: number; isError?: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => (props.isError ? "red" : "#19202e")};
  text-align: center;
  white-space: nowrap;
  padding: 11px ${({ px }) => (px ? `${px}px` : "0")};
  vertical-align: middle;
  @media (${RESPONSIVE_SIZES.lg}) {
    padding: 11px 2px;
  }
`;

const StyledSkeleton = styled.div`
  height: 17px;
  width: -webkit-fill-available;
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: #fafafa;
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
`;
