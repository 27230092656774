import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { includes, isEmpty } from "lodash";
import {
  useDeletePayoutTemplateMutation,
  useGetPayoutTemplatesQuery,
} from "api/baseAPI/payoutTemplates";
import { useGetUsersMePayoutsPermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { FilterBar } from "components/table/FilterBar";
import { StyledCell, StyledRow, Table } from "components/table/Table";
import {
  MenuItemAction,
  TableRowActions,
} from "components/table/TableRowActions";
import { Box } from "UI/Box";
import { ReactComponent as DeleteIcon } from "utils/img/delete-midle.svg";
import { ReactComponent as EditIcon } from "utils/img/edit.svg";
import { ReactComponent as PlusIcon } from "utils/img/plus-outlined.svg";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";

const initParameters = {
  page: "1",
  page_size: "10",
  search: "",
  history_id: "",
  template_id: "",
};

export const PayoutTemplates = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Выплаты"), ""],
    [t("Шаблоны"), ""],
  ];

  const tableCellTitles = [
    t("ID"),
    t("Название"),
    t("Количество получателей"),
    t("Сумма"),
    t("Дата и время"),
  ];

  const navigate = useNavigate();

  const getPayoutsPermissionsQuery = useGetUsersMePayoutsPermissionsQuery();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const { queryParams, querySetters } = useUrlQuery<{
    page: string;
    page_size: string;
    search: string;
    history_id: string;
    template_id: string;
  }>(initParameters);

  const { page, page_size, search } = queryParams;

  const { set_page, set_page_size, set_search } = querySetters;

  const { data, isFetching, refetch } = useGetPayoutTemplatesQuery({
    page: queryParams.page,
    page_size: queryParams.page_size,
    search: queryParams.search,
  });

  const handleSetPage = (pageVal: number) => {
    set_page(String(pageVal));
  };

  const handleSetRowsPerPage = (rows: number) => {
    set_page("1");
    set_page_size(String(rows));
  };

  const handleSetSearch = (searchVal: string) => {
    set_page("1");
    set_search(searchVal);
  };

  const [deleteTemplate, deleteTemplateMeta] =
    useDeletePayoutTemplateMutation();

  useSuccessNotification([deleteTemplateMeta]);
  useErrorNotification([deleteTemplateMeta]);

  return (
    <Layout title={t("Шаблоны")} breadCrumbs={breadCrumbs}>
      <Box py={32} px={24}>
        <Table
          tableCellTitles={tableCellTitles}
          isLoading={isFetching}
          filterBar={
            <FilterBar
              search={search}
              setSearch={handleSetSearch}
              refetch={refetch}
            />
          }
          count={data ? data.count : 0}
          page={Number(page)}
          rowsPerPage={Number(page_size)}
          setPage={handleSetPage}
          setRowsPerPage={handleSetRowsPerPage}
        >
          {data && !isEmpty(data.results)
            ? data.results.map((row) => (
                <StyledRow key={row.id}>
                  <StyledCell>{row.id}</StyledCell>
                  <StyledCell>{row.title}</StyledCell>
                  <StyledCell>{row.element_count}</StyledCell>
                  <StyledCell>{amountFormat(row.element_amount)}</StyledCell>
                  <StyledCell>{dateFormat(row.created_at)}</StyledCell>
                  <StyledCell>
                    <TableRowActions
                      actions={[
                        ...(includes(
                          getPayoutsPermissionsQuery.data,
                          "change_payouttemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={1}
                                onClick={() =>
                                  navigate({
                                    pathname: "/payout-create",
                                    search: `?template_id=${row.id}`,
                                  })
                                }
                              >
                                <EditIcon /> &nbsp;{t("Редактировать")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getPayoutsPermissionsQuery.data,
                          "add_registry"
                        )
                          ? [
                              <MenuItemAction
                                key={2}
                                onClick={() =>
                                  navigate({
                                    pathname: "/payout-create",
                                    search: `?template_id=${row.id}`,
                                  })
                                }
                              >
                                <PlusIcon /> &nbsp;{t("Создать выплаты")}
                              </MenuItemAction>,
                            ]
                          : []),
                        ...(includes(
                          getPayoutsPermissionsQuery.data,
                          "delete_payouttemplate"
                        )
                          ? [
                              <MenuItemAction
                                key={3}
                                onClick={() => deleteTemplate({ id: row.id })}
                              >
                                <DeleteIcon /> &nbsp;{t("Удалить")}
                              </MenuItemAction>,
                            ]
                          : []),
                      ]}
                    />
                  </StyledCell>
                </StyledRow>
              ))
            : null}
        </Table>
      </Box>
    </Layout>
  );
};
