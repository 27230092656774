import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { find } from "lodash";
import styled from "styled-components";
import { useGetPointServicesQuery } from "api/baseAPI/pointServices";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { RESPONSIVE_SIZES } from "utils/tools";

export const ServiceSelect = ({
  services,
  onChange,
  selectedService,
  point,
}: {
  services: number[];
  onChange: (id: number) => void;
  selectedService: number | null;
  point: number | null;
}) => {
  const { t } = useTranslation();
  const [isOpenWalletMenu, setIsOpenWalletMenu] = useState(false);
  const { pageBackground } = getColors();
  const pointServices = useGetPointServicesQuery({
    page: "1",
    page_size: "100",
    search: "",
    point: String(point || ""),
    service__in: services.join(","),
  });

  const handleSelectWallet = (id: number) => {
    setIsOpenWalletMenu(!isOpenWalletMenu);
    onChange(id);
  };

  const getService = useMemo(() => {
    if (selectedService) {
      const service = find(
        pointServices.data?.results,
        (item) => item?.service?.id === selectedService
      )?.service;
      return service ? `${service?.id} | ${service.name}` : "";
    }
    return "";
  }, [selectedService, pointServices.data?.results]);

  return (
    <StyledWrapperSelect border={pageBackground}>
      <StyledRelative>
        <StyledSelect
          background={pageBackground}
          onClick={() => setIsOpenWalletMenu(!isOpenWalletMenu)}
        >
          <Typography variant="inputText">{getService}</Typography>
          <StyledArrowUpDown open={isOpenWalletMenu} />
        </StyledSelect>
        {isOpenWalletMenu && (
          <>
            <StyledModalOverlay
              onClick={() => setIsOpenWalletMenu(!isOpenWalletMenu)}
            />
            <StyledWrapperModal>
              {pointServices.data?.results &&
              pointServices.data?.results?.length > 0 ? (
                <div>
                  {pointServices.data.results.map((item) => (
                    <StyledItem
                      key={item.id}
                      active={
                        !!(
                          selectedService &&
                          item.service?.id === selectedService
                        )
                      }
                      background={pageBackground}
                      onClick={() => handleSelectWallet(item.service?.id)}
                    >
                      <Typography variant="subtitle2">{`${item?.service?.id} | ${item?.service?.name}`}</Typography>
                    </StyledItem>
                  ))}
                </div>
              ) : (
                <Typography variant="subtitle2">
                  {t("Нет услуг для выбора")}
                </Typography>
              )}
            </StyledWrapperModal>
          </>
        )}
      </StyledRelative>
    </StyledWrapperSelect>
  );
};

const StyledWrapperSelect = styled.div`
  width: 100%;
  border: 1px solid ${(props: { border: string }) => props.border};
  margin-bottom: 24px;
  @media (${RESPONSIVE_SIZES.overMd}) {
    min-width: 300px;
  }
`;

const StyledRelative = styled.div`
  position: relative;
`;

const StyledSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 19px;
  background: ${(props: { background: string }) => props.background};
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 8px;
  path {
    fill: #19202e !important;
  }
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 8px;
          padding-left: 0;
          transform: rotate(180deg);
        `
      : ""};
`;

const StyledModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 150;
  top: 0;
  left: 0;
`;

const StyledWrapperModal = styled.div`
  position: absolute;
  padding: 16px 24px;
  width: -webkit-fill-available;
  color: #000000;
  left: 0;
  background-color: white;
  z-index: 160;
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  max-height: 400px;
  overflow-y: auto;
`;

const StyledItem = styled.div`
  padding: 12px 32px;
  margin-right: -24px;
  margin-left: -24px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props: { background: string; active: boolean }) => props.background};
  ${(props) => (props.active ? `background: ${props.background};` : "")}
  &:hover {
    background: ${(props) => props.background};
  }
`;
