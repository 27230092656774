import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Wallet } from "api/baseAPI/wallets";
import { Typography } from "UI/Typography";
import { getColors } from "utils/getColors";
import { ReactComponent as ArrowUpDown } from "utils/img/arrow-up-down.svg";
import { RESPONSIVE_SIZES } from "utils/tools";
import { useCurrencyIcon } from "utils/useStyle";
import { useNumberFormatter } from "hooks/useNumberFormatter";

export const WalletSelectWidget = ({
  available_wallets,
  onChange,
  selectedWallet,
  walletData,
}: {
  available_wallets: { id: number; name: string }[];
  onChange: (wallet: { id: number; name: string }) => void;
  selectedWallet: { id: number; name: string } | null;
  walletData: Wallet | undefined | null;
}) => {
  const { t } = useTranslation();
  const [isOpenWalletMenu, setIsOpenWalletMenu] = useState(false);
  const { pageBackground } = getColors();
  const currencyIcon = useCurrencyIcon(walletData ? walletData.currency : "");
  const { amountFormat } = useNumberFormatter();

  const handleSelectWallet = (wallet: { id: number; name: string }) => {
    setIsOpenWalletMenu(!isOpenWalletMenu);
    onChange(wallet);
  };

  return (
    <StyledWrapperWalletSelect border={pageBackground}>
      <StyledRelative>
        <StyledSelectWallet
          background={pageBackground}
          onClick={() => setIsOpenWalletMenu(!isOpenWalletMenu)}
        >
          {selectedWallet !== null ? (
            <Typography variant="inputText">
              {selectedWallet.id
                ? `${selectedWallet.id} | ${selectedWallet.name}`
                : " "}
            </Typography>
          ) : null}
          <StyledArrowUpDown open={isOpenWalletMenu} />
        </StyledSelectWallet>
        {isOpenWalletMenu && (
          <>
            <StyledModalOverlay
              onClick={() => setIsOpenWalletMenu(!isOpenWalletMenu)}
            />
            <StyledWrapperModal>
              {available_wallets && available_wallets?.length > 0 ? (
                <div>
                  {available_wallets.map((item) => (
                    <StyledItem
                      key={item.id}
                      active={
                        !!(selectedWallet && item.id === selectedWallet.id)
                      }
                      background={pageBackground}
                      onClick={() => handleSelectWallet(item)}
                    >
                      <Typography variant="subtitle2">{`${item?.id} | ${item?.name}`}</Typography>
                    </StyledItem>
                  ))}
                </div>
              ) : (
                <Typography variant="subtitle2">
                  {t("Нет кошельков для выбора")}
                </Typography>
              )}
            </StyledWrapperModal>
          </>
        )}
      </StyledRelative>
      {selectedWallet !== null ? (
        <StyledWrapperBalance>
          <TypographySubtitle3 variant="subtitle3">
            {t("Доступный баланс кошелька")}
          </TypographySubtitle3>
          <StyledBalance>
            {currencyIcon}
            {walletData?.balance !== undefined &&
            walletData?.balance_min !== undefined
              ? amountFormat(
                  Number(walletData?.balance) -
                    Number(walletData?.balance_min) -
                    Number(walletData?.balance_hold) -
                    Number(walletData?.rolling_reserve_amount)
                )
              : "0.00"}
          </StyledBalance>
        </StyledWrapperBalance>
      ) : (
        <StyledWrapperBalance>
          <TypographySubtitle3 variant="body">
            {t("Кошелек не выбран")}
          </TypographySubtitle3>
        </StyledWrapperBalance>
      )}
    </StyledWrapperWalletSelect>
  );
};

const StyledWrapperWalletSelect = styled.div`
  width: 100%;
  border: 1px solid ${(props: { border: string }) => props.border};
  @media (${RESPONSIVE_SIZES.overMd}) {
    min-width: 300px;
  }
`;

const StyledRelative = styled.div`
  position: relative;
`;

const StyledSelectWallet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 19px;
  background: ${(props: { background: string }) => props.background};
`;

const StyledArrowUpDown = styled(ArrowUpDown)`
  padding-left: 8px;
  path {
    fill: #19202e !important;
  }
  ${(props: { open: boolean }) =>
    props.open
      ? `
          padding-right: 8px;
          padding-left: 0;
          transform: rotate(180deg);
        `
      : ""};
`;

const StyledModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 150;
  top: 0;
  left: 0;
`;

const StyledWrapperModal = styled.div`
  position: absolute;
  padding: 16px 24px;
  width: -webkit-fill-available;
  color: #000000;
  left: 0;
  background-color: white;
  z-index: 160;
  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.18);
  max-height: 400px;
  overflow-y: auto;
`;

const StyledItem = styled.div`
  padding: 12px 32px;
  margin-right: -24px;
  margin-left: -24px;
  cursor: pointer;
  border-bottom: 1px solid
    ${(props: { background: string; active: boolean }) => props.background};
  ${(props) => (props.active ? `background: ${props.background};` : "")}
  &:hover {
    background: ${(props) => props.background};
  }
`;

const StyledWrapperBalance = styled.div`
  padding: 16px;
`;

const TypographySubtitle3 = styled(Typography)`
  color: #374258 !important;
  margin-bottom: 8px !important;
`;

const StyledBalance = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #19202e;
  display: flex;
`;
