import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { UPIPayoutForm } from "pages/payout/logic/forms/UPIPayoutForm";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class UPIPayoutMethod extends BasePayoutMethod implements IPayoutMethod {
  slug: string = "upi";

  kind: PayoutKind = PayoutKinds.UPI;

  name: string = "UPI";

  mobileName: string = this.name;

  icon: ReactElement = (<></>);

  filenameExample = "sample-upi";

  jsonExample = [
    {
      "Bank Account Number": "input 1",
      Sum: 23.33,
      Note: "note 1",
      IFSC: "сode 1",
      "Full name account holder": "name 1",
      "Phone number": "1234567890",
      Email: "some@mail.com",
      "Recipient address": "addr 1",
    },
    {
      "Bank Account Number": "input 2",
      Sum: 50,
      Note: "note 2",
      IFSC: "сode 2",
      "Full name account holder": "name 2",
      "Phone number": "1023456789",
      Email: "some2@mail.com",
      "Recipient address": "addr 2",
    },
    {
      "Bank Account Number": "input 3",
      Sum: 5050,
      Note: "note 3",
      IFSC: "сode 3",
      "Full name account holder": "name 3",
      "Phone number": "1203456789",
      Email: "some3@mail.com",
      "Recipient address": "addr 3",
    },
  ];

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.upi_payouts &&
        this.getService(this.pointManager?.service_config?.upi_payouts)) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <UPIPayoutForm payoutMethod={payoutMethod} />
  );

  customerAccountInput() {
    return <></>;
  }

  customerAccountValidationSchema = yup
    .string()
    .required("Укажите счет получателя");
}
