import { ReactElement } from "react";
import * as yup from "yup";
import { PayoutKind, PayoutKinds } from "api/baseAPI/payouts";
import { CustomerAccountInputProps } from "pages/payout/logic/forms/components/FormLines";
import { PhoneInput } from "pages/payout/logic/forms/components/inputs/PhoneInput";
import { ReactComponent as PhoneIcon } from "utils/img/phone.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class PhonePayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "phone";

  kind: PayoutKind = PayoutKinds.PHONE;

  name: string = "По номеру телефона";

  mobileName: string = "По номеру тел.";

  icon: ReactElement = (<PhoneIcon />);

  filenameExample = "sample-phone";

  jsonExample = [
    {
      "Номер телефона": "0930000001",
      Сумма: 23.33,
      Примечание: "note 1",
    },
    {
      "Номер телефона": "0970000002",
      Сумма: 50,
      Примечание: "note 2",
    },
    {
      "Номер телефона": "0990000003",
      Сумма: 5050,
      Примечание: "note 3",
    },
  ];

  additional_payout_information = null;

  customerAccountInput = (props: CustomerAccountInputProps) => (
    <PhoneInput {...props} />
  );

  customerAccountValidationSchema = yup
    .string()
    .min(12, "Неправильный формат номера телефона!")
    .required("Введите номер телефона");

  get serviceIds() {
    return (
      (this.pointManager?.service_config?.phone_payouts_available &&
        this.pointManager?.service_config?.default_phone_payout_service &&
        this.getService(
          this.pointManager?.service_config?.default_phone_payout_service
        )) ||
      null
    );
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return null;
  }
}
