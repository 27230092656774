import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormikErrors } from "formik";
import { every, isArray } from "lodash";
import { useLazyGetPayoutTemplateQuery } from "api/baseAPI/payoutTemplates";
import { Wallet, useLazyGetWalletQuery } from "api/baseAPI/wallets";
import { Layout } from "components/layouts/Layout";
import { WalletSelectWidget } from "components/widgets/WalletSelectWidget";
import { Box } from "UI/Box";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import { getFirstService } from "utils/helpers";
import { useErrorNotification } from "utils/notificationWrappers";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import {
  setSelectedWallet as setSelectedWalletInReduce,
  setWalletInfo,
} from "reducers/basicSlice";
import { IPayoutMethod, usePayouts } from "./logic/payoutMethods";
import { PayoutData, PayoutFormData } from "./logic/payoutsCreateLogic";
import { PayoutInfoPanel } from "./parts/PayoutInfoPanel";
import { PayoutTypeSelect } from "./parts/PayoutTypeSelect";
import { ServiceSelect } from "./parts/ServiceSelect";

export const PayoutCreate = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Выплаты"), ""],
    [t("Создать"), ""],
  ];

  const dispatch = useDispatch();

  const selectedPointManager = useSelectedPointManager();

  const [selectedWallet, setSelectedWallet] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const [walletData, setWalletData] = useState<Wallet | null>(null);
  const [selectedService, setSelectedService] = useState<number | null>(null);

  const [selectedPayoutMethod, setSelectedPayoutMethod] =
    useState<IPayoutMethod | null>(null);

  const [payouts, setPayouts] = useState<Record<string, PayoutData>>({});

  const { payoutMethods, loading } = usePayouts(
    walletData?.currency ? walletData?.currency : "",
    walletData,
    selectedPayoutMethod?.slug || "",
    selectedService
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Hack for triger update component when validation executed
  const [, setFormErrors] = useState<FormikErrors<PayoutFormData>>();

  const subscriber = {
    setPayouts: (payoutsData: Record<string, PayoutData>) =>
      setPayouts(payoutsData),
    setSaveToTemplate: () => {},
    setIsSubmitting: (isSubmittingData: boolean) =>
      setIsSubmitting(isSubmittingData),
    setDelay: () => {},
    setFormErrors: (errors: FormikErrors<PayoutFormData>) => {
      setFormErrors(errors);
    },
  };

  selectedPayoutMethod?.addSubscriber("infopanel", subscriber);

  const [getWalletData] = useLazyGetWalletQuery();

  useEffect(() => {
    if (selectedWallet?.id) {
      getWalletData({
        id: selectedWallet.id,
      })
        .unwrap()
        .then((data) => {
          setWalletData(data);
          dispatch(setWalletInfo(data));
        });
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (selectedPointManager?.payout_wallets?.length) {
      setSelectedWallet(selectedPointManager.payout_wallets[0]);
    }
  }, [selectedPointManager.payout_wallets]);

  useEffect(() => {
    if (payoutMethods.length) {
      setSelectedPayoutMethod(
        payoutMethods[payoutMethods.findIndex((method) => method.isActive)]
      );
    }
  }, [payoutMethods.length, payoutMethods]);

  useEffect(() => {
    if (selectedPayoutMethod?.serviceIds) {
      setSelectedService(getFirstService(selectedPayoutMethod?.serviceIds));
    }
  }, [selectedPayoutMethod]);

  const [getPayoutTemplate] = useLazyGetPayoutTemplateQuery();

  const template_id =
    new URLSearchParams(window.location.search).get("template_id") || "";

  const [payoutMethodIsNotActive, setPayoutMethodIsNotActive] =
    useState<boolean>(false);

  useEffect(() => {
    if (every(payoutMethods, (payout) => payout.isLoaded)) {
      if (template_id) {
        getPayoutTemplate({ id: Number(template_id) })
          .unwrap()
          .then((data) => {
            const payoutMethod = payoutMethods.find(
              (pm) => pm.kind === data.kind
            );
            if (payoutMethod) {
              if (payoutMethod.isActive) {
                setSelectedPayoutMethod(payoutMethod);
              } else {
                console.error("payout method is not active");
                setPayoutMethodIsNotActive(true);
              }
            }
          });
      }
    }
  }, [payoutMethods, template_id]);

  useErrorNotification(
    [{ isError: payoutMethodIsNotActive }],
    t("Указанный в шаблоне тип выплат не доступен")
  );

  return (
    <Layout title={t("Выплаты")} breadCrumbs={breadCrumbs}>
      <Grid container>
        <Grid item sm={12} xxl={8} mb={24} style={{ maxWidth: "100%" }}>
          <Box p={24}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              mb={20}
              vSpace={12}
            >
              <Grid item md={5}>
                <Typography variant="h6">
                  {t("Введите данные для отправки выплат")}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <PayoutTypeSelect
                  loading={loading}
                  payoutMethods={payoutMethods}
                  selectedPayoutMethod={selectedPayoutMethod}
                  onChange={(payout) => {
                    selectedPayoutMethod?.resetForm();
                    setSelectedPayoutMethod(payout);
                    setSelectedService(null);
                  }}
                />
              </Grid>
            </Grid>

            {!loading && selectedPayoutMethod?.form(selectedPayoutMethod)}
          </Box>
        </Grid>

        <Grid item sm={12} xxl={4} mb={24} style={{ backgroundColor: "white" }}>
          <Box p={24}>
            <Box mb={24}>
              <WalletSelectWidget
                available_wallets={selectedPointManager?.payout_wallets}
                onChange={(wallet) => {
                  setSelectedWallet(wallet);
                  dispatch(
                    setSelectedWalletInReduce({
                      ...wallet,
                    })
                  );
                }}
                selectedWallet={selectedWallet}
                walletData={walletData}
              />
            </Box>
            {!!selectedPayoutMethod?.serviceIds &&
              isArray(selectedPayoutMethod?.serviceIds) &&
              selectedPayoutMethod.serviceIds.length > 1 && (
                <ServiceSelect
                  services={selectedPayoutMethod.serviceIds}
                  selectedService={selectedService}
                  onChange={(service) => setSelectedService(service)}
                  point={selectedPayoutMethod?.pointId}
                />
              )}
            {!!selectedPayoutMethod && (
              <PayoutInfoPanel
                selectedPayoutMethod={selectedPayoutMethod}
                payouts={payouts}
                isSubmitting={isSubmitting}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};
