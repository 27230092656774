import React from "react";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import styled from "styled-components";
import { Typography } from "UI/Typography";
import { Box } from "UI/Box";
import { ReactComponent as Download } from "utils/img/download.svg";
import { ReactComponent as PixLogo } from "utils/img/pix-logo.svg";
import {
  Transaction,
  useLazyGetTransactionServiceFieldsQuery,
} from "api/baseAPI/transactions";
import { useNumberFormatter } from "hooks/useNumberFormatter";

type Props = {
  transaction: Transaction;
};

export const PixReceipt = ({ transaction }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const { amountFormat } = useNumberFormatter();

  const [cancelTransactionServiceField, cancelTransactionServiceFieldMeta] =
    useLazyGetTransactionServiceFieldsQuery();

  const handleSetRowsPerPage = () => {
    cancelTransactionServiceField({
      id: transaction?.id,
    })
      .unwrap()
      .finally(() => {
        setTimeout(() => {
          const receipt = document.getElementById("receipt");
          // eslint-disable-next-line new-cap
          const pdf = new jsPDF();
          if (!receipt) return;
          html2canvas(receipt).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg");
            const date = new Date(transaction.created_at);
            pdf.addImage(imgData, "JPEG", 0, 0, 0, 0);
            pdf.save(
              `receipt_${date
                .toLocaleTimeString()
                .slice(0, 5)
                .replaceAll(":", "_")}_${date
                .toLocaleDateString()
                .replaceAll(".", "_")}.pdf`
            );
          });
        }, 200);
      });
  };

  return (
    <>
      <Box onClick={handleSetRowsPerPage} style={{ cursor: "pointer" }}>
        <Download style={{ verticalAlign: "middle" }} /> {t("Скачать чек")}
      </Box>
      <Box style={{ position: "fixed", top: "-2000px" }}>
        <StyledReceipt id="receipt">
          <PixLogo />
          <Typography
            mt={12}
            style={{ fontSize: "28px", lineHeight: "38px", fontWeight: 700 }}
          >
            {`Comprovante de transferência # ${transaction.id}`}
          </Typography>
          <Box mt={18} flex justifyContent="space-between">
            <Typography
              variant="h5"
              style={{ lineHeight: "54px", color: "#4D4D4D", fontWeight: 400 }}
            >
              Nome:
            </Typography>
            <Typography
              variant="h5"
              style={{ lineHeight: "54px", color: "#4D4D4D", fontWeight: 700 }}
            >
              JUST PAGAMENTO LTDA
            </Typography>
          </Box>
          <Box flex justifyContent="space-between">
            <Typography
              variant="h5"
              style={{ lineHeight: "54px", color: "#4D4D4D", fontWeight: 400 }}
            >
              CPF/NIF:
            </Typography>
            <Typography
              variant="h5"
              style={{ lineHeight: "54px", color: "#4D4D4D", fontWeight: 700 }}
            >
              326598
            </Typography>
          </Box>
          <Box mt={90} flex justifyContent="space-between">
            <Box
              style={{
                background: "#FFFFFF",
                width: "164px",
                flexDirection: "column",
              }}
              mr={10}
              py={31}
              pl={24}
              flex
              justifyContent="center"
            >
              <Typography
                variant="h5"
                textAlign="left"
                style={{ lineHeight: "18px", fontWeight: 400 }}
              >
                Destinatário:
              </Typography>
            </Box>
            <Box style={{ background: "#FFFFFF", width: "430px" }} p={25}>
              <Typography
                variant="h4"
                style={{
                  lineHeight: "30px",
                  fontWeight: 400,
                  textAlign: "left",
                }}
              >
                {cancelTransactionServiceFieldMeta?.data?.find(
                  (el) => el.field_slug  ===  "tax_id"
                )?.field_value || ""}
              </Typography>
            </Box>
          </Box>
          <Typography
            mt={40}
            variant="h5"
            textAlign="right"
            style={{ lineHeight: "18px", fontWeight: 400, color: "#4D4D4D" }}
          >
            Quantia
          </Typography>
          <Typography
            mt={20}
            variant="h3"
            textAlign="right"
            style={{
              fontSize: "32px",
              lineHeight: "32px",
              color: "#678C14",
              fontWeight: 700,
            }}
          >
            {`${amountFormat(transaction.amount, 4)} ${
              transaction.amount_currency || ""
            }`}
          </Typography>
        </StyledReceipt>
      </Box>
    </>
  );
}

const StyledReceipt = styled.div`
  height: 733px;
  width: 821px;
  background: #f1f1f1;
  padding: 64px 70px 50px 70px;
  box-sizing: border-box;
  font-family: "Roboto";
  text-align: center;
  margin: 0 auto;
`;
