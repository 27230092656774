import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { includes, isEmpty, map } from "lodash";
import {
  useCreatePageTemplateMutation,
  useGetPageQuery,
} from "api/baseAPI/invoicing";
import { useGetUsersMeInvoicingPermissionsQuery } from "api/baseAPI/user";
import { Layout } from "components/layouts/Layout";
import { TabList } from "components/TabList";
import { Box } from "UI/Box";
import { Button } from "UI/Button";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { getCurrencyIcon } from "utils/useStyle";
import { useDateFormatter } from "hooks/useDateFormatter";
import { useNumberFormatter } from "hooks/useNumberFormatter";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { DataItem, ParamOnOff } from "./parts/AdditionalComponents";
import { PageStats } from "./parts/PageStats";
import { PageTransactionsTable } from "./parts/PageTransactionsTable";
import { PayUrlInfo } from "./parts/PayUrlInfo";

const paymentMethods = {
  1: "Card",
  2: "Apple pay",
  3: "Google pay",
  4: "Binance pay",
};

export const Page = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    [t("Платежные страницы"), ""],
    [t("Список страниц"), "/invoicing/pages"],
    [t("Просмотр платежной страницы"), ""],
  ];

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("1");

  const { pageId } = useParams<{ pageId: string }>();

  const getPage = useGetPageQuery({ uuid: pageId || "" });
  const selectedPointManager = useSelectedPointManager();
  const getInvoicingPermissions = useGetUsersMeInvoicingPermissionsQuery();
  const { dateFormat } = useDateFormatter();
  const { amountFormat } = useNumberFormatter();

  const [createPageTemplate, createPageTemplateMeta] =
    useCreatePageTemplateMutation();

  const handleChangeActiveTab = (newValue: string) => {
    navigate({ search: "" });
    setActiveTab(newValue);
  };

  const handleCreatePageTemplate = () => {
    if (!isEmpty(getPage?.data)) {
      const { data } = getPage;
      createPageTemplate({
        title: data?.title || "",
        amount_currency: data?.amount_currency || "",
        locale: data?.locale || "",
        available_locales: data?.available_locales || [],
        fixed_amount: data?.fixed_amount || "",
        service: data?.service?.id ? String(data?.service?.id) : "",
        bp_service: data?.bp_service?.id ? String(data?.bp_service?.id) : null,
        min_amount: data?.min_amount || "",
        wallet: data?.wallet?.id ? String(data?.wallet?.id) : "",
        is_reusable: data?.is_reusable || false,
        additional_fields: data?.additional_fields || [],
        preferred_amounts: data?.preferred_amounts || [],
        available_payment_methods: data?.available_payment_methods || [],
        customization: data?.customization?.id
          ? String(data?.customization?.id)
          : "",
        status: data?.status ? 1 : 0,
        prefilled_fields: data?.prefilled_fields || {},
        point: String(selectedPointManager.point?.id),
        description_template: data?.description_template || "",
        preferred_amounts_logic: data?.preferred_amounts_logic || 1,
        kind: data?.kind || 1,
      });
    }
  };

  useSuccessNotification([createPageTemplateMeta]);
  useErrorNotification([createPageTemplateMeta]);

  return (
    <Layout title={t("Просмотр платежной страницы")} breadCrumbs={breadCrumbs}>
      <Box p={24} style={{ background: "white" }}>
        <Typography variant="h5">{t("Основные параметры")}</Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={12} md={3}>
            <DataItem text={t("ID")} content={getPage?.data?.id || ""} />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Услуга")}
              content={
                getPage?.data?.service?.id
                  ? `${getPage?.data?.service?.id} | ${getPage?.data?.service.name}`
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Кошелек")}
              content={
                getPage?.data?.wallet?.id
                  ? `${getPage?.data?.wallet?.id} | ${getPage?.data?.wallet.name}`
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Валюта")}
              content={getPage?.data?.amount_currency || ""}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Минимальная сумма")}
              content={
                getPage?.data?.min_amount
                  ? amountFormat(getPage?.data?.min_amount)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Сумма")}
              content={
                getPage?.data?.fixed_amount
                  ? amountFormat(getPage?.data?.fixed_amount)
                  : ""
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Предлагаемые суммы")}
              content={
                !isEmpty(getPage?.data?.preferred_amounts) ? (
                  <Box flex alignItems="center">
                    {map(getPage?.data?.preferred_amounts, (item) => (
                      <Box
                        p={4}
                        mr={2}
                        mb={-6}
                        style={{ background: "#EAEFF7" }}
                        key={item}
                      >
                        <Typography
                          variant="subtitle3"
                          style={{ display: "flex" }}
                        >
                          <>
                            {getCurrencyIcon(
                              getPage?.data?.amount_currency || ""
                            )}
                            {amountFormat(item)}
                          </>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <DataItem
              text={t("Платежные методы")}
              content={
                !isEmpty(getPage?.data?.available_payment_methods) ? (
                  <Box flex alignItems="center">
                    {map(getPage?.data?.available_payment_methods, (item) =>
                      item === 1 || item === 2 || item === 3 || item === 4
                        ? paymentMethods?.[item]
                        : item
                    ).join(", ")}
                  </Box>
                ) : (
                  <></>
                )
              }
            />
          </Grid>
        </Grid>
        <Typography mt={24} variant="h5">
          {t("Опциональные параметры")}
        </Typography>
        <Grid container pt={24} hSpace={24} vSpace={24}>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Имя плательщика")}
              content={
                <ParamOnOff
                  includes={includes(getPage?.data?.additional_fields, "name")}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Номер телефона")}
              content={
                <ParamOnOff
                  includes={includes(getPage?.data?.additional_fields, "phone")}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Email плательщика")}
              content={
                <ParamOnOff
                  includes={includes(getPage?.data?.additional_fields, "email")}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Комментарий")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "comment"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("ФИО")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "first_name"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Дата рождения")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "birth_date"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Страна")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "country"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Город")}
              content={
                <ParamOnOff
                  includes={includes(getPage?.data?.additional_fields, "city")}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Адрес")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "address"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Почтовый индекс")}
              content={
                <ParamOnOff
                  includes={includes(
                    getPage?.data?.additional_fields,
                    "zip_code"
                  )}
                />
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Одноразовая оплата")}
              content={<ParamOnOff includes={!!getPage?.data?.is_reusable} />}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem text={t("Язык")} content={getPage?.data?.locale || ""} />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Доступные языки")}
              content={getPage?.data?.available_locales?.join(", ") || ""}
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Срок действия")}
              content={
                <Typography
                  variant="inputText"
                  style={{
                    color:
                      getPage?.data?.active_till &&
                      new Date(getPage?.data?.active_till) < new Date()
                        ? "red"
                        : "black",
                  }}
                >
                  {dateFormat(getPage?.data?.active_till || null)}
                </Typography>
              }
            />
          </Grid>
          <Grid item sm={6} md={3}>
            <DataItem
              text={t("Время создания")}
              content={dateFormat(getPage?.data?.created_at || null)}
            />
          </Grid>
          {includes(getInvoicingPermissions.data, "add_pagetemplate") ? (
            <Grid item sm={12} responsive={{ md: { mt: 32 }, sm: { mt: 8 } }}>
              <Grid container>
                <Grid item sm={6} md={3}>
                  <Button
                    variant="outlined"
                    fullwidth
                    onClick={handleCreatePageTemplate}
                  >
                    {t("Сохранить как шаблон")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
      <Box py={32} px={24}>
        <TabList
          value={activeTab}
          onChange={handleChangeActiveTab}
          items={[
            { label: t("Основная информация"), value: "1" },
            { label: t("Транзакции"), value: "2" },
          ]}
        />
        <Box mt={24}>
          <>
            {activeTab === "2" && (
              <PageTransactionsTable
                page_id={getPage.data?.id ? String(getPage.data?.id) : ""}
              />
            )}
            {activeTab === "1" && (
              <Grid container pl={24} hSpace={24}>
                <Grid item sm={12} md={4}>
                  {!!getPage.data?.pay_url && (
                    <PayUrlInfo payUrl={getPage.data?.pay_url} />
                  )}
                </Grid>
                <Grid item sm={12} md={4}>
                  <PageStats
                    pageId={pageId || ""}
                    currency={getPage?.data?.amount_currency || ""}
                  />
                </Grid>
              </Grid>
            )}
          </>
        </Box>
      </Box>
    </Layout>
  );
};
